<div class="wallet-transaction-toggle transaction-container-expanded modal show all-in-wallet slideUp-content wallet-bg p-l-5 p-r-5">
    <section class="category-heading p-r-10 p-l-10">
        <h3 class="text-center m-b-0">
            {{'Game Provider' | translate | uppercase }}
            <i class="fas fa-times" (click)="onCloseDialog()"></i>
        </h3>
    </section>
    <div>
        <div class="row m-l-0 m-r-0 p-b-15 vendor-container">
            <div class="col-3 p-t-15" *ngFor="let provider of providers[data.providerCode]">
                <div class="vendor-box" [ngClass]="{'vendor-box-maintenance': +provider.maintenance === 1, 'active-provider': provider.game_provider_code === data.activeCategory.game_provider_code}">
                    <app-favorite-game class="favorite-container" [gameProviderId]="provider.game_provider_id" [categoryCode]="data.providerCode" [isFavorite]="provider.favorite" [bigIcon]="true"></app-favorite-game>
                    <span (click)="onSelectCategory(provider)">
                        <img [src]="toJSON(provider.image)?.icon_logo" class="provider-icon" [ngClass]="{'disabled': +provider.maintenance === 1}">
                        <p>{{ provider.game_provider_name }}</p>
                        <div class="ribbon-wrapper-green-item" *ngIf="provider.type === 'APP'" [ngClass]="{'disabled' : (isLoggedIn$ | async)  && provider.maintenance !== 0}">
                          <div class="ribbon-green-item">{{ provider.type }}</div>
                        </div>
                        <div class="ribbon-wrapper-green-item" *ngIf="provider.vpn === 1" >
                          <div class="vpn-ribbon-item">VPN</div>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>