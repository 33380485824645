import { ForgotPasswordModalComponent } from './shared/forgot-password-modal/forgot-password-modal.component';
import { RewardSwalComponent } from './views/modules/member/dialogs/reward-modal/reward-swal/reward-swal.component';
import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit, Renderer2, ChangeDetectorRef, DoCheck } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Params, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { ISOCountryCode } from '@core/enums/ISO-country-code.enum';
import { AnnouncementHttpService } from '@core/services/announcement-http.service';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { LocationHttpService } from '@core/services/location-http.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { changeProfile, login } from '@core/store/auth/auth.actions';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';
import countryISOMapping from 'country-iso-3-to-2';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { SweetAlertCustomClass } from 'sweetalert2';
import { EmailVerificationHttpService } from './core/services/email-verification-http.service';
import { OneSignalHttpService } from './core/services/one-signal-http.service';
import { SeoService } from './core/services/seo.service';
import { isLoggedIn } from './core/store/auth/auth.selectors';
import { AppState } from './store/reducers';
import { environment } from '@env/environment';
import { SiteDomainService } from '@core/services/site-domain.service';
import { ContactListHttpService } from '@core/services/contact-list-http.service';
import { PredictionDataService } from '@views/modules/games/services/prediction-data.service';
import { EwalletVerificationHttpService } from '@core/services/ewallet-verification-http.service';
import { BankAccountDataService } from '@views/modules/member/services/bank-account-data.service';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { EwalletVerification } from '@core/models/ewallet-verification.model';
import { PopupConnectionErrorComponent } from '@shared/popup-connection-error/popup-connection-error.component';
import { EwalletVerificationApprovalComponent } from '@shared/ewallet-verification-approval/ewallet-verification-approval.component';
import { EwalletVerificationRejectionComponent } from '@shared/ewallet-verification-rejection/ewallet-verification-rejection.component';
import { version } from '../../package.json';
import { Announcement } from '@core/models/announcement.model';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, DoCheck {

  mode: string = window.mode;
  siteName = (environment.sitePrefix as string).toUpperCase();
  shutdownflag = environment.shutdown;
  pageTitle = '';
  isDesktopDetected = false;
  todayDate = new Date();
  rewardNotice = Number(localStorage.getItem('reward_notice'));
  currentURL = this.router.url.split('/');
  page = this.currentURL[this.currentURL.length - 1] === '' || window.location.pathname == '/' ? 'home' : this.currentURL[this.currentURL.length - 1];
  announcements$ = [];
  announcementIds = [];
  campaignCode: string;
  affiliateCode: string;

  showBackButton = false;
  backButtonList = [
    '/signup'
  ];

  ISOCountryCode = ISOCountryCode;

  showChangeCountryNotice = false;
  luckySpinDomain = environment.luckySpinDomain;

  supportedCountry = this.dropdownService.availableCountryAndLanguages;
  supportedLanguage = this.dropdownHttpService.languages;

  customClass: SweetAlertCustomClass = {
    content: 'country-not-available-content'
  };

  // TEMPORARILY
  s3RedirectURL = {
    '/EN50FSD': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+Deposit.png',
    '/EN50FSND': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+No+Deposit.png',
    '/ZH50FSD': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+Deposit.png',
    '/ZH50FSND': 'https://aiodin-pro1.s3.ap-southeast-1.amazonaws.com/promotion/#COUNTRY_LANGUAGE+-+50+Free+Spins+-+No+Deposit.png'
  }
  pathName = '';
  hostname = (window.location.hostname).replace(/^([w]{3}.)(?=[a-z0-9]+)/, '');
  countryCodeToChange: string;
  countryCode: string;
  hideAppBase = window.location.pathname === '/unsupported-country' ? true : false;
  isLoggedIn$: Observable<boolean>;
  mainDomainBlocked: boolean;
  isMember: boolean;
  // This currentDomain mean the most top / parent domain, not the sub domain
  currentDomain: string;

  // TEMPORARY USE TO HIDE LUCKY SPIN WHEEL IN PRODUCTION
  currentEnv = environment.domainEnv;

  // This dialog location is same as in BO
  // The data is hardcoded from BO and not from API
  dialogLocation = [
    { id:1  , name : 'All' },
    { id:2  , name : 'Home' },
    { id:3  , name : 'Deposit' },
    { id:4  , name : 'Withdraw' },
    { id:5  , name : 'Transfer' },
    { id:6  , name : 'Slots-Game' },
    { id:7  , name : 'Casino-Game' },
    { id:8  , name : 'Sports' },
    { id:9  , name : 'Fishing' },
    { id:10 , name : 'Promotion' },
    { id:11 , name : 'Vip' },
    { id:12 , name : 'Contact' }
  ];

  showReminder: boolean = false;
  newLink: string = null;

  onMaintenancePage = false;
  seoSetting: any;
  verificationData: EwalletVerification = null;
  newRequestIncoming = this.ewalletVerificationHttpService.newRequestIncoming;
  hiddenDomains = this.dropdownService.vipHiddenDomains;
  connectionState!: ConnectionState;
  connectionSubscription = new Subscription();
  announcementSubscription = new Subscription();

  newPromptIncoming = this.announcementHttpService.newPromptIncoming;
  rewardPrompt: Announcement = null;

  private unsubscribe: Subscription[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private deviceDetectorService: DeviceDetectorService,
    private authService: AuthHttpService,
    public loadingBar: LoadingBarService,
    private eventEmitterService: EventEmitterService,
    private memberProfileService: PortalMemberProfileHttpService,
    private announcementHttpService: AnnouncementHttpService,
    private datePipe: DatePipe,
    private location: Location,
    public dialog: MatDialog,
    private locationHttpService: LocationHttpService,
    private dropdownService: DropdownHttpService,
    private emailVerificationHttpService: EmailVerificationHttpService,
    private os: OneSignalHttpService,
    private seoService: SeoService,
    private renderer: Renderer2,
    private dropdownHttpService: DropdownHttpService,
    private siteDomainService: SiteDomainService,
    private contactListHttpService: ContactListHttpService,
    private predictionDataService: PredictionDataService,
    private connectionService: ConnectionService,
    private ewalletVerificationHttpService: EwalletVerificationHttpService,
    private bankAccountDataService: BankAccountDataService,
    private cdr: ChangeDetectorRef,
) {

    //here to decrypt and set items to localstorage if launch page and got param redirectDomain  
    if (window.location.pathname == '/gamelaunch') {
      let params = this.authService.getUrlParams(window.location.search);
      let redirectDomain = params['redirectDomain'];
      if (redirectDomain && redirectDomain == '1') {
        localStorage.removeItem('gameCategory');
        localStorage.removeItem('gameCode');
        localStorage.removeItem('gameProviderId');
        localStorage.removeItem('gameProviderCode');
        localStorage.removeItem('isPlayTechClicked');
        localStorage.removeItem('gameType');
        localStorage.removeItem('gameMode');
        localStorage.removeItem('gameProviderDemoMode');

        const decrypted_userData = params['userData'];
        const decrypted_userToken = params['accessToken'];
        if (decrypted_userData && decrypted_userToken) {
          let user = JSON.parse(decrypted_userData);
          localStorage.setItem('country_code', user['country_code']);
          localStorage.setItem('user_data', decrypted_userData);
          localStorage.setItem('user_token', decrypted_userToken);
          if (params['gameCode']) localStorage.setItem('gameCode', params['gameCode']);
          if (params['gameProviderId']) localStorage.setItem('gameProviderId', params['gameProviderId']);
          if (params['gameProviderCode']) localStorage.setItem('gameProviderCode', params['gameProviderCode']);
          if (params['gameCategory']) localStorage.setItem('gameCategory', params['gameCategory']);
          if (params['gameType']) localStorage.setItem('gameType', params['gameType']);
          if (params['gameMode']) localStorage.setItem('gameMode', params['gameMode']);
          if (params['isPlayTechClicked']) localStorage.setItem('isPlayTechClicked', params['isPlayTechClicked']);
          if (params['gameProviderDemoMode']) localStorage.setItem('gameProviderDemoMode', params['gameProviderDemoMode']);
          if (params['subCategoryCode']) localStorage.setItem('subCategoryCode', params['subCategoryCode']);
          if (params['lobbyUrl']) localStorage.setItem('lobbyUrl', params['lobbyUrl']);
          if (params['countryCode']) localStorage.setItem('country_code', params['countryCode']);
          if (params['mainDomainUrl']) localStorage.setItem('main_domain_url', params['mainDomainUrl']);
          const userData = localStorage.getItem('user_data');
          const userToken = localStorage.getItem('user_token');
          this.store.dispatch(login({ user: JSON.parse(userData), token: JSON.parse(userToken) }));
        }
      }
    }

    if (environment.production && localStorage.getItem('country_code') === 'SG') {
      document.addEventListener("DOMContentLoaded", () => {
        seoService.setJsonLd();
        seoService.renderer = this.renderer;
      });
    }
    router.events.pipe(
      tap(() => this.authService.getAffiliateAndMarketingParams()),
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.checkVersionNew();
      this.getRouteTitle(this.activatedRoute).data
        .pipe(tap(data => {
          if (window.location.pathname === '/forgot-password') {
            this.router.navigate(['/']);
            this.dialog.open(ForgotPasswordModalComponent);
          }
          this.pageTitle = data.title;
          // if (this.rewardNotice > 0 && data.title !== 'Reward History' && data.title !== 'Live Chat') {
          //   this.onRewardNotice();
          // }
        }))
        .subscribe((event: NavigationEnd) => {
          this.show();
          if (window.location.pathname == '/gamelaunch') {
            this.titleService.setTitle(this.pageTitle ? this.pageTitle : '');
          }
          else {
            this.titleService.setTitle((this.pageTitle ? this.pageTitle + ' | ' : '') + this.siteName);
          }

          if (!['/casino', '/slots', '/lottery', '/sports', '/fishing', '/esports', '/crash-game', '/table'].includes(event.url)) {
            this.seoService.execute();
          }
        });
    });
    window.onbeforeunload = () => { sessionStorage.removeItem('game_providers_balance') };
  }

  async ngOnInit() {
    this.newPromptIncoming.subscribe(() => {
      const userData = localStorage.getItem('user_data');
      this.rewardPrompt = AnnouncementHttpService.rewardPrompt;
      if (this.rewardPrompt.updateRewardPrompt == true && JSON.parse(userData).id === this.rewardPrompt.member_account_id) {
        this.eventEmitterService.onUpdateRewardBubble();
        this.onGetAnnouncements(true);
      }
    })

    if (window.location.href.includes('casino')) {
      localStorage.setItem('gameCategoryCode', 'LC');
      localStorage.setItem('gameCategoryName', 'casino');
    } else if (window.location.href.includes('slots')) {
      localStorage.setItem('gameCategoryCode', 'SL');
      localStorage.setItem('gameCategoryName', 'slots');
    }
    setTimeout(() => {
      this.onMaintenancePage = (window.location.pathname) == '/maintenance' ? true : false;
    }, 500)
    this.onCheckConnection();

    if (window.location.pathname !== '/unsupported-country') {

      // dynamic url for landing page
      const redirect_site_domain = JSON.parse(localStorage.getItem('redirect_site_domain')),
        landing_page_domain = redirect_site_domain.find(x => x.type == 2),
        game_launch_domain = redirect_site_domain.find(x => x.type == 3);
      if (game_launch_domain && window.location.href.includes(game_launch_domain['domain'])) {
        if (!(window.location.href.includes('/gamelaunch?redirectDomain'))) {
          let url = ((environment.https ? 'https://' : 'http://') + landing_page_domain['domain']);
          window.location.replace(url);
        }
      }

      localStorage.setItem('countryNotice', (this.showChangeCountryNotice) ? '1' : '0');
      this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
      this.pathName = window.location.pathname;
      document.body.classList.remove('livechat');
      this.getHostname();

      // Grab and update click count whenever affiliate
      // or marketing campaign param is present from URL
      this.route.queryParams.subscribe((params: Params) => {
        this.campaignCode = params[`campaign`];
        this.affiliateCode = params[`aff`];
        if (this.campaignCode) {
          this.authService.updateCampaignClickCount(this.campaignCode).subscribe();
        }
        if (this.affiliateCode) {
          this.authService.updateAffiliateClickCount(this.affiliateCode).subscribe();
        }
        if (Object.keys(params).length > 0) {
          localStorage.setItem('signupRoute', window.location.href);
          if (!location.href.includes('promotion') && !location.href.includes('login') && !location.href.includes('signup')) {
            const newURL = location.href.split('?')[0];
            window.history.pushState('object', document.title, newURL);
          }
        }
      });

      this.isLoggedIn$.subscribe(res => {
        this.isMember = res;
        if (res) {
          setTimeout(() => {
            this.checkReminder();
            this.onLoginVerificationPop();
            this.os.onInit().subscribe();
          }, 200)
        }
      });

      this.onVerifyEmail();
      this.ewalletVerificationPop();

      // this.browserInit();

      // Should be after browserInit!
      this.landingPage();

      // Set timeout to prevent get null value for localStorage variable
      setTimeout(() => {
        if (!this.hiddenDomains.includes(window.location.hostname)) {
          this.checkLocation();
        }
      }, 1000)

      const languageCode = localStorage.getItem('language_code');
      const userLocale = localStorage.getItem('user_locale');
      if (languageCode !== null) {
        this.translateService.use((languageCode).toLocaleLowerCase());
      } else {
        if (this.supportedLanguage[userLocale.toUpperCase()] !== undefined) {
          this.translateService.use((userLocale).toLocaleLowerCase());
        };
      }

      const userData = localStorage.getItem('user_data');
      const userToken = localStorage.getItem('user_token');
      if ((userData !== null && userToken !== null) && (userData && userToken)) {
        this.store.dispatch(login({ user: JSON.parse(userData), token: JSON.parse(userToken) }));
      }

      this.eventEmitterService.updateCountryAndLanguageEmitter.subscribe(() => {
        localStorage.removeItem('announcements_pop');
        this.getContactUs();
        this.onGetAnnouncements();
      });

      if (window.location.pathname !== '/gamelaunch') {
        var checkCountryInterval = setInterval(() => {
          if (localStorage.getItem('country_code') !== null) {
            this.countryCode = localStorage.getItem('country_code');
            setTimeout(() => {
              this.getContactUs();
              this.onGetAnnouncements();
              clearInterval(checkCountryInterval);
            }, 1000)
          }
        }, 2000);
      }

      this.redirectToS3Link();
      if (languageCode !== null) {
        languageCode.toLowerCase() === 'zh'
          ? moment.locale('zh-cn')
          : moment.locale(languageCode.toLowerCase());
      }
    }
  }

  ngDoCheck() {
    this.rewardNotice = Number(localStorage.getItem('reward_notice'));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (screenWidth < 300) { // cater for small screen size
        let viewportMetaTag = document.querySelector('meta[name="viewport"]');
        if (viewportMetaTag) {
          const content = `width=device-width, initial-scale=0.7`;
          this.renderer.setAttribute(viewportMetaTag, 'content', content);
        }
      }
    }, 500);

    if (window.location.pathname !== '/gamelaunch') {
      setTimeout(() => {
        const userData = JSON.parse(localStorage.getItem('user_data'));
        if (userData && userData.id) {
          if (!this.authService.isTokenExpired()) {
            const userProfileSub = this.memberProfileService.getProfile().pipe(
              tap(user => {
                if (user.name !== null || user.name.length > 0) {
                  this.store.dispatch(changeProfile({
                    profile: {
                      ...user,
                      id: user.id,
                      name: user.name,
                      member_group: {
                        name: user.member_group.name,
                        image: user.member_group.image,
                        trial: user.member_group.trial
                      }
                    }
                  }));
                }
              })
            ).subscribe();
            this.unsubscribe.push(userProfileSub);
          }
        }
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.map(sb => sb.unsubscribe);
    this.connectionSubscription.unsubscribe();
  }

  onLuckySpin() {
    this.router.navigate(['/luckyspin']);
  }

  onPreviousPage() {
    this.location.back();
  }

  private getContactUs() {
    this.contactListHttpService.getContactList().subscribe(res => {
      sessionStorage.setItem('contact_us', JSON.stringify(res))
      this.eventEmitterService.onUpdateContactUs();
    });
  }

  onCheckConnection() {
    this.connectionSubscription.add(
      this.connectionService.monitor().pipe(
        tap((newState: ConnectionState) => {
          this.connectionState = newState;
          if (!this.connectionState.hasNetworkConnection) {
            this.dialog.open(PopupConnectionErrorComponent, { width: 'auto' });
          } else {
            this.dialog.closeAll();
          }
        })
      ).subscribe()
    );
  }

  onGetAnnouncements(websocketPop = false) {
    const dateToday = this.datePipe.transform(this.todayDate, 'yyyy-MM-dd');
    if (localStorage.getItem('date_today') !== dateToday) {
      localStorage.setItem('date_today', dateToday);
      localStorage.removeItem('announcements_pop');
      localStorage.removeItem('marquees');
    }

    const currentURL = this.router.url.split('/');
    const currentURLRemoveLang = currentURL[currentURL.length - 1].split('?');

    let page = currentURL[currentURL.length - 1] === '' || window.location.pathname == '/' ? 'home' : currentURLRemoveLang[currentURLRemoveLang.length - 1] === '' ? 'home' : currentURLRemoveLang[currentURLRemoveLang.length - 1];
    if (currentURLRemoveLang.length > 1) page = currentURLRemoveLang[0] === '' ? 'home' : currentURLRemoveLang[0];

    this.route.queryParams.subscribe(async (params: Params) => {
      let dialogCode = null;
      if ((params['campaign'] != undefined || params['aff'] != undefined) && params['dialog']) {
        localStorage.setItem('dialog', params['dialog']);
        window.history.replaceState({},'',`${this.router.url.replace('?dialog=' + params['dialog'], '').replace('&dialog=' + params['dialog'], '')}`);
      }
      else if (params['campaign'] == undefined && params['aff'] == undefined) {
        if (params['dialog']) {
          dialogCode = params['dialog']
          localStorage.setItem('dialog', params['dialog']);
          window.history.replaceState({},'',`${this.router.url.replace('?dialog=' + dialogCode, '').replace('&dialog=' + dialogCode, '')}`);
        }
        else {
          dialogCode = localStorage.getItem('dialog');
        }
      }

      this.announcementSubscription.unsubscribe();
      this.announcementSubscription = this.announcementHttpService.getPopUp(dialogCode).subscribe(res => {
        if (dialogCode == null) {
          let announcementPopList = JSON.parse(localStorage.getItem('announcements_pop'));
          let announcementPopCodes = res.map(function(i) {
            return i['code'];
          });
          if (announcementPopCodes.length > 0 && announcementPopList != null) {
            announcementPopList = announcementPopList.filter(x => announcementPopCodes.includes(x.code));
            localStorage.setItem('announcements_pop', JSON.stringify(announcementPopList));
          }
        }

        const announcementPop = [];
        res.map((announcement: any) => {
          if (res.length > 0) announcement.content[0].session = announcement.session;
          // announcement.platform : 1 = All , 2 == Mobile , 3 == Desktop
          if (announcement.platform == 1 || announcement.platform == 2) {
            // announcement.session :  1 = All , 2 == Before login , 3 == after login
            if (localStorage.getItem('user_data') != null) {
              if (announcement.session == 1 || announcement.session == 3) {
                let seenStatus = 0;
                if (localStorage.getItem('announcements_pop') !== undefined && localStorage.getItem('announcements_pop') !== null) {
                  let announcementList = JSON.parse(localStorage.getItem('announcements_pop'))
                  let announcementListFilter = announcementList.filter(x => x.id == announcement.id)
                  if (announcementListFilter.length >0) {
                    if (announcementListFilter[0].always_pop == 0) {
                      announcementList = announcementList.filter(x => x.id != announcement.id)
                      localStorage.setItem('announcements_pop', JSON.stringify(announcementList))
                    } else {
                      seenStatus = announcementListFilter[0].seen == 1 ? 1 : 0;
                    }
                  }
                }
                announcementPop.push({
                  content: announcement.content,
                  id: announcement.id,
                  code: announcement.code,
                  location_name: announcement.location_name,
                  seen: seenStatus,
                  settings_locale_id: announcement.settings_locale_id,
                  always_pop: announcement.always_pop,
                });
              }
            } else {
              if (announcement.session == 1 || announcement.session == 2) {
                let seenStatus = 0;
                if (localStorage.getItem('announcements_pop') !== undefined && localStorage.getItem('announcements_pop') !== null) {
                  let announcementList = JSON.parse(localStorage.getItem('announcements_pop'))
                  let announcementListFilter = announcementList.filter(x => x.id == announcement.id)
                  if (announcementListFilter.length >0) {
                    if (announcementListFilter[0].always_pop == 0) {
                      announcementList = announcementList.filter(x => x.id != announcement.id)
                      localStorage.setItem('announcements_pop', JSON.stringify(announcementList))
                    } else {
                      seenStatus = announcementListFilter[0].seen == 1 ? 1 : 0;
                    }
                  }
                }
                announcementPop.push({
                  content: announcement.content,
                  id: announcement.id,
                  code: announcement.code,
                  location_name: announcement.location_name,
                  seen: seenStatus,
                  settings_locale_id: announcement.settings_locale_id,
                  always_pop: announcement.always_pop,
                });
              }
            }
          }
        });

        if (announcementPop.length > 0) {
          const popUpAnnouncementsFromLocal = localStorage.getItem('announcements_pop');
          if (popUpAnnouncementsFromLocal === null || JSON.parse(popUpAnnouncementsFromLocal).length === 0) {
            if (dialogCode) {
              announcementPop.forEach(function (item, i) {
                if (item['code'] == dialogCode) {
                  announcementPop.splice(i, 1);
                  announcementPop.unshift(item);
                }
              });
            }
            localStorage.setItem('announcements_pop', JSON.stringify(announcementPop));
            this.eventEmitterService.onSwalAnnouncementEmitter(page);
            this.onSwalFire(this.page, JSON.stringify(announcementPop), websocketPop);
          } else {
            if (JSON.stringify(announcementPop) !== JSON.stringify(this.setOldAnnouncements(popUpAnnouncementsFromLocal))) { // if the announcement list update on BO this would update the list on storage
              const updatedPopUpAnnouncements = this.mergeAllAnnouncements(announcementPop.concat(JSON.parse(popUpAnnouncementsFromLocal)));
              const finalPopUpAnnouncement = this.setFinalAnnouncement(updatedPopUpAnnouncements);
              if (dialogCode) {
                finalPopUpAnnouncement.forEach(function (item, i) {
                  if (item['code'] == dialogCode) {
                    finalPopUpAnnouncement.splice(i, 1);
                    finalPopUpAnnouncement.unshift(item);
                  }
                });
              }
              localStorage.setItem('announcements_pop', JSON.stringify(finalPopUpAnnouncement));
              this.onSwalFire(this.page, JSON.stringify(finalPopUpAnnouncement), websocketPop);
            } else {
              this.onSwalFire(this.page ,popUpAnnouncementsFromLocal, websocketPop);
            }
          }
        } else {
          localStorage.removeItem('announcements_pop');
        }
      });
    });

    const marqueeslist = [];
    this.announcementHttpService.getMarquees().subscribe(res => {
      res.map((marquees: any) => {
        if (res.length > 0) {
          // here is the login marquees
          if (localStorage.getItem('user_data') && localStorage.getItem('user_data') != null) {
            if (marquees.session == 1 || marquees.session == 3) {
              marqueeslist.push({
                contents: marquees.contents,
                id: marquees.id,
                position: marquees.position
              });
            }
          } else {
            if (marquees.session == 1 || marquees.session == 2) {
              marqueeslist.push({
                contents: marquees.contents,
                id: marquees.id,
                position: marquees.position
              });
            }
          }
        }
      });

      if (marqueeslist.length > 0) {
        const marqueesFromLocal = localStorage.getItem('marquees');
        if (marqueesFromLocal === null || JSON.parse(marqueesFromLocal).length === 0) {
          localStorage.setItem('marquees', JSON.stringify(marqueeslist));
        } else {
          if (JSON.stringify(marqueeslist) !== JSON.stringify(JSON.parse(marqueesFromLocal))) { // if the announcement list update on BO this would update the list on storage
            localStorage.setItem('marquees', JSON.stringify(marqueeslist));
          }
        }
      } else {
        localStorage.removeItem('marquees');
      }

      this.eventEmitterService.onMarqueesEmitter();
    });
  }

  onSwalFire(page: string, announcementPop?: any, websocketPop = false) {
    var currentURL = window.location.pathname.split('/');
    let Current_page = 'home';
    if (currentURL && this.currentURL.length > 1) {
      const result = currentURL.find(value => this.dialogLocation.some(item => item.name.toLowerCase() === value.toLowerCase().replace('wallet;tab=', ''))) || 'home';
      Current_page = result.replace('wallet;tab=', '').replace('forgot-password;tab=', '');
    }

    this.announcements$ = [];
    this.announcementIds = [];
    if (announcementPop !== undefined) {
      localStorage.removeItem('popup_ids');
      JSON.parse(announcementPop).map(announcement => {
        if ((announcement.location_name === Current_page || announcement.location_name === 'all') && announcement.seen === 0 && Current_page !== 'landing' && window.location.pathname !== 'gamelaunch') {
          this.announcements$.push(announcement.content);
          this.announcementIds.push(announcement.id);
        }
      });
      this.cdr.markForCheck();
      localStorage.setItem('popup_ids', JSON.stringify(this.announcementIds));

      if(websocketPop) {
        this.eventEmitterService.swalAnnouncementEmitter.emit(Current_page);
      }
    }
  }

  private checkVersionNew() {
    //const currentVersion = localStorage.getItem('version');
    localStorage.setItem('version', version);
    // if ((currentVersion != null && version != currentVersion) || currentVersion == null) {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   localStorage.setItem('version', version);
    //   if (window.location.pathname !== '/') {
    //     window.location.href = '/';
    //   }
    // }
  }
  
  private setFinalAnnouncement(updatedPopUpAnnouncements: any) { // set the final announcement before push to localstorage
    const finalPopUp = [];
    updatedPopUpAnnouncements.map((result: any) => {
      finalPopUp.push({
        ...result,
        content: result.content[0],
        location_name: result.location_name[0],
        seen: (result.settings_locale_id[0] !== result.settings_locale_id[1] || result.location_name[0] !== result.location_name[1]
          || result.content[0] !== result.content[1]) && (result.seen[0] !== result.seen[1]) ? result.seen[0] : result.seen[1],
        settings_locale_id: result.settings_locale_id[0]
      });
    });
    return finalPopUp;
  }

  private setOldAnnouncements(announcements: any) { // update localstorage seen to 0
    const popUp = [];
    JSON.parse(announcements).map(res => {
      popUp.push({
        ...res,
        seen: 0
      });
    });
    return popUp;
  }

  private mergeAllAnnouncements(allAnnouncement: any) { // merge all data but unique id
    const updatedPopUpAnnouncements = [];
    allAnnouncement.map(allAnn => {
      const existing = updatedPopUpAnnouncements.filter((v, i) => {
        return v.id === allAnn.id;
      });
      if (existing.length) {
        const existingIndex = updatedPopUpAnnouncements.indexOf(existing[0]);
        updatedPopUpAnnouncements[existingIndex].content = updatedPopUpAnnouncements[existingIndex].content.concat(allAnn.content);
        updatedPopUpAnnouncements[existingIndex].location_name = updatedPopUpAnnouncements[existingIndex].location_name.concat(allAnn.location_name);
        updatedPopUpAnnouncements[existingIndex].seen = updatedPopUpAnnouncements[existingIndex].seen.concat(allAnn.seen);
        updatedPopUpAnnouncements[existingIndex].settings_locale_id = updatedPopUpAnnouncements[existingIndex].settings_locale_id.concat(allAnn.settings_locale_id);
      } else {
        allAnn.content = [allAnn.content];
        allAnn.location_name = [allAnn.location_name];
        allAnn.seen = [allAnn.seen];
        allAnn.settings_locale_id = [allAnn.settings_locale_id];
        updatedPopUpAnnouncements.push(allAnn);
      }
    });
    return updatedPopUpAnnouncements;
  }

  onClose() {
    this.router.navigate(['/']);
  }

  show() {
    if (this.router.url == '/unsupported-country') {
      return false;
    } else {
      return true;
    }
  }

  onBackButtonClick() {
    this.location.back();
  }

  onActivate() {
    document.body.scrollTop = 0;
  }

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn;
  }

  private onRewardNotice() {
    this.dialog.open(RewardSwalComponent, {
      width: '800px',
      data: {
        type: 'notice'
      }
    });
  }

  private browserInit() {
    try {
      const languages = this.supportedCountry[this.currentDomain][localStorage.getItem('country_code')]['languages'];
      if (!languages.includes(localStorage.getItem('language_code').toUpperCase())) {
        localStorage.setItem('language_code', languages[0])
        this.translateService.use(languages[0].toLocaleLowerCase());
      }
    } catch (e) { }

    if (this.deviceDetectorService.isDesktop()) {
      let desktopPreExt = null;
      let fullDomain = '';

      // Get the desktop pre extension for the country
      try {
        Object.keys(this.supportedCountry[this.currentDomain]).forEach(key => {
          if (this.supportedCountry[this.currentDomain][key][environment.domainEnv].includes(this.hostname)) {
            desktopPreExt = this.supportedCountry[this.currentDomain][key]['desktopPreExt'][environment.domainEnv];
            throw {};
          }
        })
      } catch (e) { }

      // Append full domain
      if (desktopPreExt === null) {
        fullDomain = (environment.https ? 'https://' : 'http://') + environment.domainName;
      } else {
        fullDomain = (environment.https ? 'https://' : 'http://') + desktopPreExt + this.hostname;
      }
      this.isDesktopDetected = true;
      const marketingParams = this.authService.getUrlParams(window.location.search);
      let authSuffix = '';
      let crossLoginParam = '';
      let countryLanguageParam = '';
      if (((window.location.pathname).startsWith('/signup') || (window.location.pathname).startsWith('/login')) && Object.keys(marketingParams)) {
        authSuffix = `${(window.location.pathname).startsWith('/login') ? '/login?' : '/signup?'}${Object.keys(marketingParams).map(key => key + '=' + marketingParams[key]).join('&')}`;
      }
      // Begin:: Cross device login
      if (!(window.location.pathname).startsWith('/login') && localStorage.getItem('user_token') && localStorage.getItem('user_data')) {
        crossLoginParam = (authSuffix === '' ? '?' : '&') + `userToken=${localStorage.getItem('user_token')}&userData=${localStorage.getItem('user_data')}`;
      }
      // End:: Cross device login
      countryLanguageParam = ((authSuffix.includes('?') || crossLoginParam.includes('?')) ? '&lang=' : '?lang=') + ((localStorage.getItem('country_code') ?? 'MY') + '_' + (localStorage.getItem('language_code') ?? 'EN'))
      window.location.href = fullDomain + authSuffix + crossLoginParam + countryLanguageParam;
    }

    localStorage.setItem('sessionid_tracker', (+new Date()).toString(16).toUpperCase());
  }

  private getRouteTitle(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (((this.backButtonList.includes(this.router.url) || this.router.url.includes('/member')) && this.router.url !== '/member/account' && this.router.url !== '/member/wallet') || this.router.url.includes('/promotion/')) {
      this.showBackButton = true;
    } else {
      this.showBackButton = false;
    }

    if (activatedRoute.firstChild) {
      return this.getRouteTitle(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  // This function is to check whether should navigate to landing page
  private landingPage() {
    let countryCode = localStorage.getItem('country_code') ? localStorage.getItem('country_code') : null;
    this.countryCode = countryCode;
    Object.keys(this.supportedCountry[this.currentDomain]).forEach(key => {
      this.supportedCountry[this.currentDomain][key][environment.domainEnv].forEach(domain => {
        if ((this.supportedCountry[this.currentDomain][key]['mobilePreExt'][environment.domainEnv] + domain) == window.location.hostname) {
          countryCode = key;
        }
      });
      // if (this.supportedCountry[this.currentDomain][key][environment.domainEnv].includes(this.hostname)) {
      //   countryCode = key;
      // }
    })

    // Go landing page
    if (
      this.hostname !== 'localhost' &&
      (environment.production && !this.hiddenDomains.includes(this.hostname)) &&
      (
        countryCode === null ||
        environment.domainName.split('|').includes(this.hostname) ||
        environment.altDomainName.split('|').includes(this.hostname) ||
        environment.altDomainNameTwo.split('|').includes(this.hostname) ||
        this.checkMobileDomain(countryCode)
      )
    ) {
      this.router.navigateByUrl('/landing', {
        replaceUrl: true,
        skipLocationChange: false
      });
    }
    // Not going to landing page
    else {
      const urlParams = new URLSearchParams(window.location.search);

      // If got param, this param is pass from landing page
      if (urlParams.get('lang') !== null) {
        // E.g MY_EN
        const countryAndLanguage = urlParams.get('lang').split('_');
        localStorage.setItem('country_code', countryAndLanguage[0]);
        localStorage.setItem('language_code', countryAndLanguage[1]);
        this.translateService.use(countryAndLanguage[1].toLocaleLowerCase());
      }
      // No param
      else {
        // Production & demo env
        if (environment.production || environment.domainEnv == 'demo') {
          // MY_EN by default for VIP Hidden Domains
          if (this.hiddenDomains.includes(this.hostname)) {
            localStorage.getItem('country_code') === null ? localStorage.setItem('country_code', "MY") : '';   // Set to default value if null
            if (localStorage.getItem('language_code') === null) {
              localStorage.setItem('language_code', "EN"); // Set to default value if null
              this.translateService.use('en');
            }
          } else {
            localStorage.getItem('country_code') === null ? localStorage.setItem('country_code', countryCode) : '';   // Set to default value if null
            let languageCode = countryCode === 'ID' ? 'ID' : 'EN'; // EN by default for all country except for Indonesia

            if (localStorage.getItem('language_code') === null) {
              localStorage.setItem('language_code', languageCode);
              this.translateService.use(languageCode.toLocaleLowerCase());
            }
          }
        }
        // LocalHost or Staging
        else if (this.hostname === 'localhost' || !environment.production) {
          localStorage.getItem('country_code') === null ? localStorage.setItem('country_code', "MY") : ''; // Set to default value if null
          if (localStorage.getItem('language_code') === null) {
            localStorage.setItem('language_code', "EN"); // Set to default value if null
            this.translateService.use('en');
          }
        }
      }

      // Auto login part
      if (urlParams.get('userToken')) {
        const userToken = urlParams.get('userToken');
        const userData = urlParams.get('userData');
        localStorage.setItem('user_token', userToken);
        localStorage.setItem('user_data', userData);
      }
    }
  }

  private onVerifyEmail() {
    if (window.location.pathname === '/verify-email') {
      if (window.location.search === '') {
        window.location.assign(''); // need to reload to get the latest top bar title
      } else {
        if (window.location.search.split('?token=')[1] === '') {
          window.location.assign(''); // need to reload to get the latest top bar title
        } else {
          this.loadingBar.start();
          this.emailVerificationHttpService.verify({ token: window.location.search.split('?token=')[1] }).pipe(
            tap((res: any) => {
              localStorage.setItem('verify_email_message', JSON.stringify(res));
              this.afterOnVerification();
              this.loadingBar.complete();
            })
          ).subscribe();
        }
      }
    }
  }

  private getHostname() {
    // E.g: localhost.com
    // Get localhost.com
    if (this.hostname.includes('-vip.jk188.net')) {
      this.hostname = this.hostname.substr(this.hostname.indexOf('.') - 4);
    } else {
      if (this.hostname.split('.').length > 2) {
        this.hostname = this.hostname.substr(this.hostname.indexOf('.') + 1);
      }
    }

    // Main
    if (
      JSON.stringify(this.supportedCountry[environment.domainName]).includes('"' + this.hostname + '"') ||
      environment.domainName.split('|').includes(this.hostname)
    ) {
      sessionStorage.removeItem('mainDomainBlocked');
      this.mainDomainBlocked = false;
      this.currentDomain = environment.domainName;
    }
    // First Alternative
    else if (
      JSON.stringify(this.supportedCountry[environment.altDomainName]).includes('"' + this.hostname + '"') ||
      environment.altDomainName.split('|').includes(this.hostname)
    ) {
      sessionStorage.setItem('mainDomainBlocked', 'true');
      this.mainDomainBlocked = true;
      this.currentDomain = environment.altDomainName;
    }
    // Second Alternative
    else if (
      JSON.stringify(this.supportedCountry[environment.altDomainNameTwo]).includes('"' + this.hostname + '"') ||
      environment.altDomainNameTwo.split('|').includes(this.hostname)
    ) {
      sessionStorage.setItem('mainDomainBlocked', 'true');
      this.mainDomainBlocked = true;
      this.currentDomain = environment.altDomainNameTwo;
    } else {
      sessionStorage.removeItem('mainDomainBlocked');
      this.mainDomainBlocked = false;
      this.currentDomain = environment.domainName;
    }
  }

  private async checkLocation() {
    const pathname = window.location.pathname,
      countryCode = localStorage.getItem('country_code');
      this.countryCode = localStorage.getItem('country_code');
    if (
      window.mode != 'Members Only' &&
      (environment.production || environment.domainEnv == 'demo') &&
      (countryCode in this.supportedCountry[this.currentDomain]) &&
      pathname !== '/landing' &&
      this.hostname !== 'localhost' &&
      !environment.domainName.split('|').includes(this.hostname) &&
      !environment.altDomainName.split('|').includes(this.hostname) &&
      !environment.altDomainNameTwo.split('|').includes(this.hostname) &&
      this.supportedCountry[this.currentDomain][countryCode][environment.domainEnv].includes(this.hostname)
    ) {
      // THIS PART COMMENTED BECAUSE DONT WANT TO USE COORDINATES (GEO-LOCATION) TO GET USER LOCATION, geoLocationError FUNCTION IS USE IP ADDRESS TO GET USER LOCATION
      // if (navigator.geolocation) {
      //   const position = await this.getCoordinates();
      //   if (position['coords']) {
      //     this.geoLocationSuccess(position);
      //   } else {
      //     this.geoLocationError();
      //   }
      // } else {
      this.geoLocationError();
      // }
    } else if (
      (environment.production || environment.domainEnv == 'demo') &&
      pathname !== '/landing' &&
      !environment.domainName.split('|').includes(this.hostname) &&
      !environment.altDomainName.split('|').includes(this.hostname) &&
      !environment.altDomainNameTwo.split('|').includes(this.hostname)
    ) {
      this.isLoggedIn$.subscribe(res => {
        if (!JSON.stringify(this.supportedCountry[this.currentDomain]).includes('"' + this.hostname + '"') && !res) {
          this.alertCountryNotSupported();
        }
      });
    }
  }

  onLoginVerificationPop() {
    this.bankAccountDataService.getUnseenVerificationRecords().pipe(
      tap(res => {
        if (res.length > 0) {
          res.forEach((item) => {
            if (item.verification_settings_field.length > 0 ) {
              if (item.status == 1) {
                this.onOpenVerificationDialog(EwalletVerificationApprovalComponent, item, true);
              } else {
                this.onOpenVerificationDialog(EwalletVerificationRejectionComponent, item, true);
              }
            }
          })
        }
      })
    ).subscribe();
  }

  ewalletVerificationPop() {
    this.newRequestIncoming.subscribe(() => {
      this.verificationData = EwalletVerificationHttpService.verification;
      if (this.verificationData) {
        if (this.verificationData.status == 1) {
          this.onOpenVerificationDialog(EwalletVerificationApprovalComponent, this.verificationData, false);
        } else {
          this.onOpenVerificationDialog(EwalletVerificationRejectionComponent, this.verificationData, false);
        }
      }
    })
  }

  onOpenVerificationDialog(componentRef: any, verificationData: any, loginPop: boolean) {
    var bank_id = loginPop == true ? verificationData.id : verificationData.member_bank_account_id;
    var dialogRef = this.dialog.open(componentRef, {
      width: 'auto',
      data: {
        bankAccountId: bank_id,
        bank_name: verificationData.bank_name,
        account_number: verificationData.account_number,
        reason: verificationData.reason,
        verificationSetting: verificationData
      } 
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.eventEmitterService.onUpdateBankList();
      this.eventEmitterService.onReloadBankList();
      if (verificationData.status == 2) {
        this.eventEmitterService.onUpdateVerificationEmitter();
      }
      this.bankAccountDataService.updateVerificationSeen(bank_id).pipe().subscribe();
    });
  }

  private getCoordinates() {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, resolve, { timeout: 5000 });
    });
  }

  private geoLocationSuccess(position) {
    const getCountryISO2 = countryISOMapping;
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    this.locationHttpService.getByCoordinates(longitude, latitude).subscribe(res => {
      const iso2CountryCode = getCountryISO2(res.CountryCode)
      if (iso2CountryCode != localStorage.getItem('country_code') && (iso2CountryCode in this.supportedCountry[this.currentDomain])) {
        this.showChangeCountryNotice = true;
        this.countryCodeToChange = iso2CountryCode;
      }
    })
  }

  private geoLocationError() {
    this.locationHttpService.getIpAddress().subscribe(res => {
      if (res['country_code']) {
        this.geolocationChange(res['country_code'].toUpperCase());
      } else {
        const xhr = new XMLHttpRequest();
        const url = `https://geolocation-db.com/json/${res['ip']}`;
        xhr.open('GET', url);
        xhr.send();
        xhr.onloadend = () => {
          if (xhr.response) {
            const address = JSON.parse(xhr.response);
            this.geolocationChange(address.country_code.toUpperCase());
          }
        }
      }
    })
  }

  private geolocationChange(country_code: string) {
    if (country_code != localStorage.getItem('country_code') && country_code in this.supportedCountry[this.currentDomain]) {
      this.showChangeCountryNotice = true;
      localStorage.setItem('countryNotice', '1');
      this.countryCodeToChange = country_code;
      this.countryCode = country_code;
    }
  }

  onChangeCountrySite() {
    var urlParams = new URLSearchParams(window.location.search), lang: string = this.countryCodeToChange.toUpperCase() + '_';
    urlParams.delete('lang');
    if (this.countryCodeToChange.toUpperCase() == 'ID') {
      lang += 'ID';
    } else if (this.countryCodeToChange.toUpperCase() == 'TH') {
      lang += 'TH';
    } else {
      lang += this.supportedCountry[this.currentDomain][this.countryCodeToChange.toUpperCase()]['languages'][0];
    }
    urlParams.append('lang', lang);
    window.location.assign((environment.https ? 'https://' : 'http://') + window.location.hostname + window.location.pathname + '?' + urlParams.toString());
  }

  onChangeCountryStatus() {
    this.showChangeCountryNotice = false;
    localStorage.setItem('countryNotice', (this.showChangeCountryNotice) ? '1' : '0');
    this.eventEmitterService.onCountryNotice();

  }

  private afterOnVerification() {
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        window.location.assign('/member/profile'); // need to reload to get the latest top bar title
      } else {
        window.location.assign(''); // need to reload to get the latest top bar title
      }
    });
  }

  private alertCountryNotSupported() {
    var url = (environment.https ? 'https://' : 'http://') + environment.mobileDomainName;
    // Direct redirect to landing page
    window.location.assign(url + '/landing');
  }

  private redirectToS3Link() {
    if (this.s3RedirectURL[this.pathName]) {
      var redirectURL = this.s3RedirectURL[this.pathName] + '';
      var countryCode = localStorage.getItem('country_code');
      var languageCode = this.pathName.substr(1, 2);
      redirectURL = redirectURL.replace('#COUNTRY_LANGUAGE', countryCode + '_' + languageCode);
      window.location.href = redirectURL;
    }
  }

  private checkMobileDomain(countryCode: string) {
    if (countryCode != null) {
      let availableMobileDomain = [],
        mobilePreExt = this.supportedCountry[this.currentDomain][countryCode].mobilePreExt[environment.domainEnv],
        hostname = window.location.hostname;
      this.supportedCountry[this.currentDomain][countryCode][environment.domainEnv].forEach(item => {
        availableMobileDomain.push(mobilePreExt + item);
      });
      return !availableMobileDomain.includes(hostname);
    }
    return true;
  }

  private checkReminder() {
    if (!this.shutdownflag && environment.sitePrefix == 'bp9') {
      this.newLink = window.location.hostname;//this.supportedCountry[this.currentDomain][localStorage.getItem('country_code')][environment.domainEnv][0];
      let doNotShowAgain = localStorage.getItem('showReminder');
      if (this.mode != 'Members Only' && (doNotShowAgain == null || doNotShowAgain == 'true')) {
        this.showReminder = true;
        localStorage.setItem('showReminder', 'true');
      } else {
        this.showReminder = false;
      }
    }
  }

  onDoNotShowAgain() {
    localStorage.setItem('showReminder', 'false');
    this.showReminder = false;
  }

  onRTP() {
    window.location.href = 'https://rtp.bp9.xyz';
  }

  checkLivechat(){

    if(((document.getElementsByClassName('livechat') && document.getElementsByClassName('livechat').length > 0))
      ||(document.getElementById('chat-widget-container'))){
      return 'liveChat';
    }
    if((document.getElementsByClassName('sb-chat') && document.getElementsByClassName('sb-chat').length > 0)){
      return 'sbChat';
    }
    return false;
  }
}
