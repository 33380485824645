import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { GameProviderHttpService } from '@core/services/game-provider-http.service';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-game-menu',
  templateUrl: './game-menu.component.html',
  styleUrls: ['./game-menu.component.scss']
})
export class GameMenuComponent implements OnInit, AfterContentInit, AfterViewInit {
  svg: any = svgIconList;

  @Input()
  fixed: string;

  @Input()
  providers: any;

  @Input()
  gameCategoryCode: string;

  @Input()
  fromHome: string;

  @Input()
  gameCategoryName: string;

  countryCode = localStorage.getItem('country_code');
  languageCode = localStorage.getItem('language_code');

  activeProviderTab: string;

  tabCount = 0;
  tabWidth = "0 0 20%";

  isSlotsPage$ = this.gameProviderHttpService.isSlotsPage$;
  isDemo$ = this.gameProviderHttpService.isDemo$;
  activeType: string;
  isLoggedIn$: Observable<boolean>;
  slideDownDemo = false;
  gameProviderDemoMode = localStorage.getItem('gameProviderDemoMode');
  slotgameinit = true;
  
  constructor(
    private store: Store<AppState>,
    private eventEmitterService: EventEmitterService,
    private gameProviderHttpService: GameProviderHttpService,
    public dialog: MatDialog,
    private el: ElementRef,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.slideDown(JSON.parse(localStorage.getItem('isDemoSelected')), false);
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        this.countryCode = localStorage.getItem('country_code');
      }
    });
    this.activeProviderTab = this.gameCategoryCode ? this.gameCategoryName : null;
    this.eventEmitterService.topBarTabVar = this.eventEmitterService.topBarTabEmitter.subscribe(() => {
      this.tabCount = 0;
      this.providers = JSON.parse(localStorage.getItem('available_providers'));
      this.countryCode = localStorage.getItem('country_code');
      if (this.providers !== undefined) {
        Object.keys(this.providers).forEach((key) => {
          if (this.providers[key].length > 0 && key !== 'CK') {
            this.tabCount++;
          }
        });
      }
    });
    if (this.providers !== undefined) {
      this.tabCount = 0;
      Object.keys(this.providers).forEach((key) => {
        if (this.providers[key].length > 0 && key !== 'CK') {
          this.tabCount++;
        }
      });
    }

    this.eventEmitterService.demoSlideDownEmitter.subscribe(res => {
      this.slideDown(res)
    });

    this.eventEmitterService.getGameType = this.eventEmitterService.passGameType.subscribe(res => {
      this.activeType = res;
    });

    this.eventEmitterService.gameProviderDemoModeEmitter.subscribe(res => {
      this.gameProviderDemoMode = res;
    });

    // this.eventEmitterService.sideBarGameCategoryEmitter.subscribe(res => {
    //   this.activeProviderTab = res;

    //   switch (res.toLowerCase()) {
    //     case 'casino':
    //       this.onSelectCategoryTab('LC', res);
    //       break;
    //     case 'slots':
    //       this.onSelectCategoryTab('SL', res);
    //       break;
    //     case 'lottery':
    //       this.onSelectCategoryTab('LT', res);
    //       break;
    //     case 'sports':
    //       this.onSelectCategoryTab('SP', res);
    //       break;
    //     case 'fishing':
    //       this.onSelectCategoryTab('FS', res);
    //       break;
    //     case 'esports':
    //       this.onSelectCategoryTab('ES', res);
    //       break;
    //     case 'crash-game':
    //       this.onSelectCategoryTab('CG', res);
    //       break;
    //     case 'table':
    //       this.onSelectCategoryTab('TB', res);
    //       break;
    //   }
    // });

    if (localStorage.getItem('gameCategoryCode') != undefined && localStorage.getItem('gameCategoryName') != undefined) {
      // this.onSelectCategoryTab(localStorage.getItem('gameCategoryCode'), localStorage.getItem('gameCategoryName'));
      this.slotgameinit = false;
    }
    
  }

  ngAfterContentInit(): void {
    this.activeType = localStorage.getItem('activeType');
  }

  ngAfterViewInit(): void {
    this.setTab();
  }

  onSelectCategoryTab(gameCategoryCode: string, gameCategoryName: string) {
    this.eventEmitterService.onSlotsGamePage(false);
    localStorage.setItem('gameCategoryCode', gameCategoryCode);
    localStorage.setItem('gameCategoryName', gameCategoryName);
    this.eventEmitterService.onCasinoGamePage(false);
    // Forcely remove active class in other tabs
    (this.el.nativeElement as HTMLElement).querySelectorAll('.oc-item').forEach(row => {
      row.classList.remove('active-category-tab')
    });

    const gameCategory = {
      code: gameCategoryCode,
      name: gameCategoryName
    };
    this.activeProviderTab = gameCategoryName;
    this.eventEmitterService.onSelectProviderTabEmitter(gameCategory);

    if (gameCategoryCode === 'SL' || gameCategoryCode === 'LC') {
      if(gameCategoryCode === 'SL'){
        this.gameProviderHttpService.isSlotsPage$.next(true);
        if(!this.slotgameinit && !this.fromHome){
          this.eventEmitterService.onSlotsGamePage(true);
          this.router.navigateByUrl('/slots-game/');
        }
      }else{
        if(!this.slotgameinit && !this.fromHome){
          this.eventEmitterService.onCasinoGamePage(true);
          this.router.navigateByUrl('/casino-game/');
        }
      }
    } else {
      this.gameProviderHttpService.isSlotsPage$.next(false);
    }
    this.gameProviderHttpService.toggleSlotsDemoStorage();
  }

  isBelongFromRoute(slug: string) {
    if ((window.location.pathname).startsWith(`/${slug}`) || this.activeProviderTab === slug) {
      return true;
    }

    return false;
  }

  private slideDown(isDemo: boolean, onSwitch = true) {
    if (onSwitch) {
      of(null).pipe(
        delay(500), tap(() => {
          this.slideDownDemo = isDemo;
        }
        )).subscribe();
    } else {
      this.slideDownDemo = isDemo;
    }
  }

  private setTab() {
    let width = (100 / this.tabCount);
    this.tabWidth = `0 0 ${width}%`;
    const elements = document.querySelectorAll('.category-tabs');

    for (let i = 0; i < elements.length; i++) {
      (elements[i] as HTMLElement).style.flex = this.tabWidth;
    }
  }

  private openDialogBy(componentRef: any, onSwitch: boolean) {
    this.dialog.open(componentRef, {
      data: {
        onSwitch: onSwitch
      }
    });
  }

}
