// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Service
import { WebsocketService } from './websocket.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { EwalletVerification } from '@core/models/ewallet-verification.model';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { BankHttpService } from '@core/services/bank-http.service';

@Injectable({
  providedIn: 'root'
})
export class EwalletVerificationHttpService {

  static verification: EwalletVerification = null;

  newRequestIncoming = new Subject();
  newRequestIncomingUpdateVerificationSetting = new Subject();
  messages$ = new Subject<any[]>();

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private websocketService: WebsocketService,
    private eventEmitterService: EventEmitterService,
    private bankHttpService: BankHttpService,
  ) {}

  async init(memberAccountId: number) {
    this.ewalletVerificationtWebsocket(memberAccountId);
  }

  async updateVerificationSettingsInit() {
    this.updateVerificationSettingsWebsocket();
  }

  private ewalletVerificationtWebsocket(memberAccountId: number) {
    this.websocketService
      .getChannel('ewallet-verification-channel.' + memberAccountId)
      .listen(`.EwalletVerificationEvent`, (data: EwalletVerification) => {
        if (data.status == 1 || data.status == 2) {
          EwalletVerificationHttpService.verification = data;
        } else {
            EwalletVerificationHttpService.verification = null;
        }
        this.newRequestIncoming.next();
      });
  }

  private updateVerificationSettingsWebsocket() {
    this.websocketService
      .getChannel('update-verification-settings-channel')
      .listen(`.UpdateVerificationSettingsEvent`, (data) => {
        if (data) {
          this.eventEmitterService.onUpdateVerificationEmitter();
          this.bankHttpService.getAllBanks().subscribe(res => {
            sessionStorage.setItem('all_bank', JSON.stringify(res))
          });
          this.eventEmitterService.onUpdateBankList();
          this.eventEmitterService.onReloadBankList();
          
          this.newRequestIncomingUpdateVerificationSetting.next();
        }
        
      });
  }

}
