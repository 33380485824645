<!-- Popup -->
<div [ngClass]="{'rewards-dialog-div': !minimizeDialog}">
    <div class="modal-box rewards-dialog" [ngClass]="{'rewards-dialog-minimize': minimizeDialog}" id="rewards-dialog">
      <div class="modal1">
        <div class="rewards-dialog-content">
        <button title="Close (Esc)" type="button" class="mfp-close" (click)="onTogglePopup()">×</button>
        <span [innerHTML]="svg.rewardIcon | safeHtml"></span>
        <h4>{{ "You have unclaimed rewards" | translate | uppercase }}</h4>
        <!-- <p>{{ "Rewards Available" | translate }}: <span>{{rewardsData?.pending_reward}}</span></p> -->
        <p class="description-text">{{ "Don't miss out. Redeem now before they expire" | translate }}!</p>

        <div class="d-flex justify-content-around">
            <button type="button" class="btn-yellow-gra w-50 ml-1 bottom-action-btn" (click)="onRedirect()">{{ 'Redeem Now' | translate| uppercase }}</button>
        </div>

        <label><input type="checkbox" class="m-r-5" (change)="onNotShowAgain($event)"> {{ 'Do not show again' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- Ballon -->
  <div
    *ngIf="showBallon"
    class="bubble d-flex justify-content-center align-items-center"
    [ngClass]="{'hide-ballon': (!showBallon || rewardsData == null), 'rewards-floating-div': minimizeDialog, 'rewards-floating-div-hide': !minimizeDialog}"
    id="rewards-floating-bubble"
  >
    <span class="badge-btn">{{rewardsData?.pending_reward}}</span>
    <div class="rewards-floating-content" (click)="onTogglePopup()">
      <span [innerHTML]="svg.rewardIcon | safeHtml"></span>
    </div>
  </div>
  