<div class="modal show sign-in" aria-labelledby="mySmallModalLabel">
   <div class="modal-dialog-centered">
      <div class="login-modal">
         <div class="modal-bg modal-signin">
            <div class="modal-header pb-0">
               <h4 class="modal-title" id="myModalLabel">
                  <span *ngIf="this.data.reupload === true" [innerHTML]="svg.receiptIcon | safeHtml" class="bill mr-2"></span>
                  {{ this.verificationTitle }}
               </h4>
               <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                  (click)="onCloseDialog()">×</button>
            </div>
            <div class="container">
               <hr>
               <div class="col-12 p-l-0 p-r-0">
                  <ng-container *ngIf="this.data.reupload === true else Other">
                     <div class="ewallet-detail mb-4">
                        <p class="text-center m-b-0">
                           {{'Reason' | translate}}: {{ this.data.reason }}
                        </p>
                     </div>
                  </ng-container>
                  <ng-template #Other>
                     <div class="promotion-table promotion-info text-color text-justify" [innerHTML]="this.verificationContent"></div>
                  </ng-template>
               </div>
               <form class="row" [formGroup]="form" id="ewallet-verification-modal-form" name="ewallet-verification-modal-form">
                  <ng-container formGroupName="verification_details">
                     <ng-container *ngFor="let value of verificationField; let i = index" [formGroupName]="value.verification_setting_field_id">
                        <!-- Upload Document -->
                        <div class="col-12 p-l-0 p-r-0" *ngIf="value.type_name == 'Upload File'">
                           <p>{{ value.label }}</p>
                           <div class="row">
                              <div class="col d-flex flex-row flex-wrap dark">
                                 <div class="receipt-box d-flex justify-content-center align-items-center" *ngFor="let image of uploaded_images[value.verification_setting_field_id]; let imgIndex = index">
                                    <img *ngIf="!image.isPdf" [src]="image.path" alt="{{ 'Image Preview' | translate }}" />
                                    <iframe *ngIf="image.isPdf" width="100%" height="100%" [src]="image.path" frameborder="0" style="border:0" allowfullscreen></iframe>
                                    <div class="trash-img-div d-flex justify-content-end align-items-start">
                                       <span [innerHTML]="svg.trashIcon | safeHtml" (click)="onRemoveFileMulti(imgIndex, value.verification_setting_field_id)"></span>
                                    </div>
                                 </div>
                                 <div *ngIf="uploaded_images[value.verification_setting_field_id]?.length < max_receipts" class="receipt-box d-flex justify-content-center align-items-center">
                                    <input type="file" accept="image/*, application/pdf" (change)="onUploadFileMulti($event, value.verification_setting_field_id, i)" #myFileInput hidden />
                                    <span *ngIf="!uploadInProgress[i]" [innerHTML]="svg.uploadIcon | safeHtml" class="upload-action-btn" (click)="myFileInput.click()"></span>
                                    <span *ngIf="uploadInProgress[i]" class="spinner-border"></span>
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 p-0">
                              <p>{{ 'Max file size is 10MB'| translate}}</p>
                           </div>
                        </div>
                        <!-- Text Field -->
                        <div class="col-12 p-l-0 p-r-0" *ngIf="value.type_name == 'Textbox'">
                           <p>{{ value.label }}</p>
                           <input type="text" class="form-control form-control-sm form-input-style m-b-10" formControlName="verification_text" />
                        </div>
                     </ng-container>
                  </ng-container>
                  <div class="col-12 p-r-0 p-l-0 text-center">
                     <button type="submit" class="btn-blue-gra m-t-15 m-b-15 w-100" [ngClass]="{'disabled': !form.valid}" [disabled]="!form.valid" (click)="onSave()">{{ 'Submit' | translate }}</button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</div>
<!--End modal-->
<app-swal-alert [message]="messages$ | async" [success]="isSuccess" [ewalletVerification]="true"></app-swal-alert>