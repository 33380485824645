import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BankHttpService } from '@core/services/bank-http.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MemberBankHttpService } from '@core/services/member-bank-http.service';
import { MemberDepositWithdrawLastOptionHttpService } from '@core/services/member-deposit-withdraw-last-option-http.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { UploadHttpService } from '@core/services/upload-http.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { BankWrapperModalComponent } from '@shared/bank-wrapper-modal/bank-wrapper-modal.component';
import * as moment from 'moment';
import { OwlDateTimeInputDirective } from 'ng-pick-datetime/date-time/date-time-picker-input.directive';
import { of, Subscription } from 'rxjs';
import { catchError, delay, tap } from 'rxjs/operators';
import { PortalDepositHttpService } from '../../../../../core/services/portal-deposit-http.service';
import Swal, { SweetAlertCustomClass } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { BankMaintenanceHourService } from '@core/services/bank-maintenance-hour.service';
import { DepositLimit } from '@core/models/deposit-limit.model';
import { QRCodeElementType } from 'angularx-qrcode';
import { CryptoTokenHttpService } from '@core/services/crypto-token-http.service';
import { TwoStepDepositService } from '../../services/two-step-deposit.service';
import { TwoStepDepositDataService } from '../../services/two-step-deposit-data.service';
import svgIconList from 'assets/icons.json';
import { FileCompressionPipe } from 'app/pipes/file-compression.pipe';
declare const $: any;
@Component({
  selector: 'app-portal-deposit',
  templateUrl: './portal-deposit.component.html',
  styleUrls: ['./portal-deposit.component.scss']
})
export class PortalDepositComponent implements OnInit, OnDestroy {
  svg: any = svgIconList;

  @ViewChild('tooltipName') tooltipName: MatTooltip;
  @ViewChild('tooltipNumber') tooltipNumber: MatTooltip;
  @ViewChild('tooltipAddress') tooltipAddress: MatTooltip;
  @ViewChild(OwlDateTimeInputDirective) datePicker: OwlDateTimeInputDirective<any>;
  @ViewChildren('focusInput') focusInput: QueryList<ElementRef>;
  @ViewChild('myFileInput') myFileInput;
  @ViewChild('bankMaintenanceHourTemplate') bankMaintenanceHourTemplate: ElementRef;

  public initial_state = {
    elementType: "canvas" as QRCodeElementType,
  }

  public data_model = {
    ...this.initial_state,
  }
  GATEWAY_PDXQR = this.twoStepDepositDataService.GATEWAY_PDXQR;
  GATEWAY_PDXVA = this.twoStepDepositDataService.GATEWAY_PDXVA;

  dropdown = {
    paymentMethods: [],
    banks: null,
    merchantBankDeposits: this.dropdownService.merchantBankAccountsDeposit,
    paymentGateways: [],
  };

  showBanks = false;
  showTokens = false;
  showNetwork = false;
  showPreloader = false;
  showPaymentMethodPreloader = true;

  bankAccount = {
    name: '',
    number: null
  };
 
  depositFieldType: DepositLimit['deposit_field_type'] = 'input_field';
  defaultAmountButton = [30, 100, 200, 300, 500, 1000];
  amountButton = [...this.defaultAmountButton];
  currencyCode = '$';
  form: FormGroup;
  messages$ = this.portalDepositHttpService.messages$;
  isSuccess = this.portalDepositHttpService.isSuccess;
  selectedBank = null;
  selectedPGBank = null;
  selectedMethod: any;
  showChannelDropdown = false;
  methodSelected = null;
  bankSelected = null;
  uploadInProgress: boolean;
  isBankTransfer: boolean;
  depositLimit: DepositLimit = null;
  submitted = false;
  disableButton = false;
  announcements$ = [];
  announcementIds = [];
  showMethods = false;
  showAmountButtons = false;
  fileName: string;
  expandClass = '';
  checkValidity = false;
  slider = {
    bankHref: '#collapseBank',
    bankId: 'collapseBank',
    receiptHref: '#collapseReceipt',
    receiptId: '#collapseReceipt'
  };
  IDRAmount: string;
  windowInnerHeight: number;
  merchantBankDeposits$ = [];

  amountList = [];
  selectedAmount = null;
  selectedChannel = null;
  amountDropdownSettings = {
    enableSearchFilter: false,
    singleSelection: true,
    text: this.translateService.instant("Select Amount"),
    primaryKey: 'value',
    labelKey: 'value',
    showCheckbox: false,
    tagToBody: false
  };
  paymentChannelDropdownSettings = {
    enableSearchFilter: false,
    singleSelection: true,
    text: this.translateService.instant("Select Channel"),
    primaryKey: 'id',
    labelKey: 'channel_name',
    showCheckbox: false,
    disabled: false,
    tagToBody: false
  };

  pgBankDropdown = [];
  pgItemsDisplay = false;

  memberMerchantBank = JSON.parse(sessionStorage.getItem('member_merchant_bank'));
  showAmountSection = false;
  showPaymentGateway = false;
  emptyPaymentGateway = {
    code: '',
    id: null,
    image_path: '',
    logo_path: '',
    maintenance_end_time: null,
    maintenance_start_time: null,
    name: '',
    display_name: '',
    redirect: null,
    channel_name_short: '',
  };
  selectedPaymentGateway = { ...this.emptyPaymentGateway };
  countryCode = localStorage.getItem('country_code');
  languageCode = localStorage.getItem('language_code')?.toLowerCase();
  userGmtOffset = null;
  offlinebankButtonClass = true;
  bankMaintenanceHour:any = [];
  gmt: any;
  currentTime:any;
  currentDayOfWeek:any;
  currentDate:any;
  yesterdayTime: any;
  yesterday: any;
  yesterdayDate: any;

  itemsDisplay = false; // for bank maintenance
  merchantBankItemsDisplay = false;
  qrImg: any = null;
  displayBankDropdown = true;
  max_receipts = 10;
  uploaded_receipts = [];
  uploaded_receipts_form = [];

  availableTokens:any;
  filteredTokens = [];
  selectedToken: any;
  selectedNetwork: any;
  networkDropdownList = [];
  selectedTokenNetwork: any;
  selectedCryptoInfo: any;

  // for two-step payment flow
  currentStep = 1;
  twoStepPaymentPGs = this.twoStepDepositDataService.getTwoStepDepositPGs();
  pgQrCodeSrc = '';
  stepTwoDeposit = null;
  pdxvaStepTwoData: {
    bankName: '',
    bankShortCode: '',
    accountNo: ''
  };

  // countdown
  hours = '00';
  minutes = '00';
  seconds = '00';

  isCompressed = false;

  private countdownInterval: any;

  private subscription = new Subscription();
  private formSubscription = new Subscription();
  private messageSubscription = new Subscription();
  public elementType: QRCodeElementType;
  constructor(
    private dropdownService: DropdownHttpService,
    private portalDepositHttpService: PortalDepositHttpService,
    private uploadHttpService: UploadHttpService,
    public loadingBar: LoadingBarService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private eventEmitterService: EventEmitterService,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    private portalMemberProfileHttpService: PortalMemberProfileHttpService,
    private bankHttpService: BankHttpService,
    private depositLastOptionService: MemberDepositWithdrawLastOptionHttpService,
    private memberBankHttpService: MemberBankHttpService,
    private translateService: TranslateService,
    private bankMaintenanceHourService: BankMaintenanceHourService,
    public twoStepDepositService: TwoStepDepositService,
    public twoStepDepositDataService: TwoStepDepositDataService,
    private fileCompression: FileCompressionPipe,
    private cryptoTokenHttpService: CryptoTokenHttpService
  ) { 
    this.elementType = this.data_model.elementType;
  }

  ngOnInit() {
    const user_data = JSON.parse(localStorage.getItem('user_data'));
    if (user_data?.gmt !== null) {
      this.userGmtOffset = user_data.gmt;
    }

    // Change step to step two if pending callback two-step deposit exists in local storage,
    // then skipping all other initialization used in step 1
    const lsStepTwoDeposit = this.twoStepDepositDataService.getLSStepTwoDeposit();
    if (lsStepTwoDeposit) {
      // this.selectedMethodName = this.twoStepDepositService.getLSStepOneSelectedMethodName();
      this.selectedPaymentGateway = JSON.parse(this.twoStepDepositDataService.getLSStepOneSelectedPaymentGateway());
      this.stepTwoDeposit = JSON.parse(lsStepTwoDeposit);

      this.goToStepTwoPayment(this.stepTwoDeposit);
      return;
    }

    // Step 1 initialization below
    if (this.countryCode.toUpperCase() === 'TH') {
      this.amountButton = [100, 200, 300, 500, 1000, 2500];
    }
    this.windowInnerHeight = window.innerHeight;

    if (JSON.parse(localStorage.getItem('user_data')).currency !== null) {
      this.currencyCode = JSON.parse(localStorage.getItem('user_data')).currency.code;
      this.countryCode = localStorage.getItem('country_code');
    }
    this.formInit();

    this.eventEmitterService.buttonEmitter.subscribe(() => {
      this.disableButton = false;
    });
    this.eventEmitterService.swalAnnouncementVar = this.eventEmitterService.swalAnnouncementEmitter.subscribe(page => {
      if (page === 'deposit') {
        this.onSwalFire();
      }
    });
    this.onSwalFire();

    this.eventEmitterService.updateProfileNameEmitter.subscribe(() => {
      this.portalMemberProfileHttpService.getProfile().subscribe(res => {
        localStorage.setItem('profile', res.name);
      });
    });

    // added checking for member bank account, so as to avoid bank acc modal prompting again if member has bank details already
    if (sessionStorage.getItem('member_bank_accounts') === null) {
      this.memberBankHttpService.getMemberBanks().subscribe(res => {
        sessionStorage.setItem('member_bank_accounts', JSON.stringify(res));
      });
    }

    //display last option for user
    this.dropdownService.paymentMethods.subscribe((paymentMethods) => {
      this.showPaymentMethodPreloader = false;
      this.dropdown.paymentMethods = paymentMethods;
      this.onShowMethods();
      this.depositLastOptionService.getLastOption(1).subscribe(lastOption => {
        if (lastOption) {
          let indexOfLastPaymentMethodOption = paymentMethods?.findIndex(item => item.code === lastOption.payment_method_code);
          if (indexOfLastPaymentMethodOption >= 0) {
            this.onSelectPaymentMethod(paymentMethods[indexOfLastPaymentMethodOption], true);
          }

          //bank/online transfer/ewallet
          if (lastOption.payment_method_code === 'BOT1' || lastOption.payment_method_code === 'EWT1') {
            let indexOfLastMerchantBankOption = this.memberMerchantBank?.findIndex(item => item.id === lastOption.merchant_bank_id);

            if (indexOfLastPaymentMethodOption >= 0) {
              this.selectBank(this.memberMerchantBank[indexOfLastMerchantBankOption])
              if (this.selectedBank != null) {
                this.onGetLimit();
              }
            }
          }

          if (this.isPaymentGatewayMethod(lastOption.payment_method_code)) {
            this.bankHttpService.getPaymentGatewayList(lastOption.payment_method_code).subscribe(
              res => {
                this.dropdown.paymentGateways = res;

                if (lastOption?.show_channel) {
                  this.dropdown.paymentGateways = this.dropdown.paymentGateways.map((pg, idx) => ({
                    ...pg,
                    channel_logo: `channel-${idx + 1}-${this.languageCode}.png`,
                    channel_name: `Channel ${idx + 1}`,
                    channel_name_short: `CH ${idx + 1}`,
                  }));
                }

                this.onShowPaymentGateway();
                this.form.controls['payment_gateway_code'].setValidators([Validators.required]);
                this.form.controls['payment_gateway_code'].updateValueAndValidity();
                if (lastOption.payment_method_code === 'UPI') {
                  this.form.controls['payupiid'].setValidators([Validators.required]);
                  this.form.controls['payupiid'].updateValueAndValidity();
                }
                let indexOfLastPaymentGatewayOption = this.dropdown.paymentGateways?.findIndex(item => item.code === lastOption.payment_gateway_code);
                if (indexOfLastPaymentGatewayOption !== -1) {
                  this.onSelectPaymentGateway(this.dropdown.paymentGateways[indexOfLastPaymentGatewayOption])
                }
              }
            );
          }
        }
      });
    })

    this.dropdown.merchantBankDeposits.subscribe(res => {
      sessionStorage.setItem('member_merchant_bank', JSON.stringify(res));
      this.memberMerchantBank = JSON.parse(sessionStorage.getItem('member_merchant_bank'));

      //get bank_id to get bank maintenance hour
      let bank_ids = [];
      JSON.parse(sessionStorage.getItem('member_merchant_bank')).forEach(merchant_banks => {
        if(merchant_banks['bank_id']){
          bank_ids.push(merchant_banks['bank_id']);
        }
      });
      //get maintenance hour
      this.bankMaintenanceHourService.getBankMaintenanceHours(bank_ids).subscribe(res => {
        localStorage.setItem('bank_maintenance_hour', JSON.stringify(res));
        this.formatBankMaintenanceHour(JSON.parse(localStorage.getItem('bank_maintenance_hour'))['rows']);
      });
    });

    this.eventEmitterService.updateMemberMerchantBankSessionEmitter.subscribe(() => {
      this.dropdown.merchantBankDeposits.subscribe(res => {
        res.forEach((item, index) => {
          this.gmt = JSON.parse(sessionStorage.getItem('member_merchant_bank'))[index]['gmt'];
          this.isBankMaintenance(item, this.gmt);
          if(item['showBank'] == true && this.merchantBankItemsDisplay == false) {
            this.merchantBankItemsDisplay = true;
          }
        });

        sessionStorage.setItem('member_merchant_bank', JSON.stringify(res));
        this.memberMerchantBank = JSON.parse(sessionStorage.getItem('member_merchant_bank'));
        this.onSelectPaymentMethod(this.methodSelected);
      });
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if (!this.twoStepDepositDataService.getLSStepTwoDeposit()) {
      return;
    }

    if (!confirm()) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();

    // clear countdown to avoid timeout dialog showing up after user exited deposit page
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  getCountdownDuration(expiryDatetime) {
    const expiryDate = new Date(expiryDatetime);
    const currentDate = new Date();
    const countdownDuration = Math.floor((expiryDate.getTime() - currentDate.getTime()) / 1000); // Calculate the difference in seconds
    return countdownDuration;
  }

  startCountdown(duration) {
    if (duration < 0) {
      this.twoStepDepositService.showDepositExpiredMsg(this.stepTwoDeposit);
      return;
    }

    let countdown = duration;

    this.countdownInterval = setInterval(() => {
      const hours = Math.floor(countdown / 3600);
      const minutes = Math.floor((countdown % 3600) / 60);
      const seconds = Math.floor(countdown % 60);

      this.hours = this.padNumber(hours);
      this.minutes = this.padNumber(minutes);
      this.seconds = this.padNumber(seconds);

      countdown--;

      if (countdown < 0) {
        clearInterval(this.countdownInterval);
        const stepTwoDeposit = this.twoStepDepositDataService.getLSStepTwoDeposit();

        if (stepTwoDeposit) {
          this.twoStepDepositService.showDepositExpiredMsg(this.stepTwoDeposit);
        }
      }
    }, 1000);
  }

  saveQRCode(src) {
    var link = document.createElement("a");
    link.href = src;
    link.download = "qr_code.png";
    link.click();
  }

  // group day together if same maintenance hours
  formatBankMaintenanceHour(bankMaintenanceHour){
    bankMaintenanceHour.forEach(x => {
      let result = {
        bank_name: x.bank_name,
        bank_code: x.bank_code,
        desktop_image: x.desktop_image,
        mobile_image: x.mobile_image,
        enable_all_day: x.enable_all_day,
        all_day: x.all_day,
        other_day: [],
        gmt: x.gmt,
        display: x.display,
        availability_control: x.availability_control
      }

      if((x.display == 1 || x.display == 3) && this.itemsDisplay == false) {
        this.itemsDisplay = true;
      }

      if(x.enable_all_day){
        this.bankMaintenanceHour.push(result);
      } else {
        let other_day = [];
        if(x.enable_monday){
          other_day.push({day: 1, time: x.monday});
        }
        if(x.enable_tuesday){
          other_day.push({day: 2, time: x.tuesday});
        }
        if(x.enable_wednesday){
          other_day.push({day: 3, time: x.wednesday});
        }
        if(x.enable_thursday){
          other_day.push({day: 4, time: x.thursday});
        }
        if(x.enable_friday){
          other_day.push({day: 5, time: x.friday});
        }
        if(x.enable_saturday){
          other_day.push({day: 6, time: x.saturday});
        }
        if(x.enable_sunday){
          other_day.push({day: 7, time: x.sunday});
        }

        other_day = other_day.sort((a, b)=> a.day - b.day);

        let last;
        let lastTime;
        let time = [];
        let continuousDays = [];
        let continuousDay = [];

        other_day.forEach((x, i)=>{
          if(i == 0){
            continuousDay.push(x.day);
            time.push(x.time);
          } else {
            if(x.day - last == 1 && JSON.stringify(x.time) === JSON.stringify(lastTime)){
              continuousDay.push(x.day);
              if(i == other_day.length - 1) {
                continuousDays.push(continuousDay);
                time.push(x.time);
              }
            } else {
              continuousDays.push(continuousDay);
              continuousDay = [x.day];
              time.push(x.time);
            }
          }
          last = x.day;
          lastTime = x.time;
        });

        if(continuousDays.length){
          continuousDays.forEach((continuousDay, i) => {
            if(continuousDay.length > 1){
              let startIndex = other_day.map(x=> x.day).indexOf(continuousDay[0]);
              let length = continuousDay.length;
              other_day.splice(startIndex, length, {startDay: continuousDay[0], endDay: continuousDay[length-1], time: time[i]});
            }
          });
        }

        result.other_day = other_day;
        this.bankMaintenanceHour.push(result);
      }

    })
  }

  getDay(day){
    switch (day) {
      case 1: return "Mon";
      case 2: return "Tue";
      case 3: return "Wed";
      case 4: return "Thu";
      case 5: return "Fri";
      case 6: return "Sat";
      case 7: return "Sun";
      default: return;
    }
  }

  onSwalFire() {
    if (localStorage.getItem('announcements_pop') !== null) {
      localStorage.removeItem('popup_ids');
      JSON.parse(localStorage.getItem('announcements_pop')).map(announcement => {
        if ((announcement.location_name === 'all' || announcement.location_name === 'deposit') && announcement.seen === 0) {
          this.announcements$.push(announcement.content);
          this.announcementIds.push(announcement.id);
        }
      });
      localStorage.setItem('popup_ids', JSON.stringify(this.announcementIds));
    }
  }

  onCloseDialog() {
    this.formInit();
    this.onReset();
  }

  onUpdateAnnouncement() {
    let isSeen = 0;
    const announcementPop = [];

    JSON.parse(localStorage.getItem('announcements_pop')).forEach(announcement => {
      this.announcementIds.includes(announcement.id) ? isSeen = 1 : isSeen = 0;

      announcementPop.push({
        ...announcement,
        seen: announcement.seen == 1 ? 1 : isSeen,
      });
    });

    localStorage.setItem('announcements_pop', JSON.stringify(announcementPop));
  }

  onGetLimit(paymentGatewayId?: number, merchantBankId?: number) {
    this.depositLimit = null;

    const path = paymentGatewayId !== undefined
        ? `?payment_gateway_id=${paymentGatewayId}&merchant_bank_id=${merchantBankId}`
        : `?merchant_bank_id=${this.selectedBank}`;

    this.portalDepositHttpService.getLimit(path)
      .subscribe(res => {
        this.updateAmountButtonAndValidators(res);

        if (paymentGatewayId === undefined) {
          if (this.depositLimit.require_receipt == 0) {
            this.form.controls['receipt_path'].setValidators([]);
          }

          this.form.controls['receipt_path'].updateValueAndValidity();
        }
      });
  }

  updateAmountButtonAndValidators(depositLimitRes: any) {
    this.depositLimit = depositLimitRes;
    this.currencyCode = this.depositLimit.currency_code;
    this.depositFieldType = 'input_field'; // default as input_field, change if needed if shortcut amount > 0

    if (this.depositLimit && this.depositLimit.shortcut_amount.length > 0) {
      this.depositFieldType = this.depositLimit.deposit_field_type;
      this.amountButton = this.depositLimit.shortcut_amount;

      if (this.depositFieldType === 'dropdown') {
        this.amountList = this.amountButton.map(value => ({ value }));
      }

    } else {
      const thAmountButton = [100, 200, 300, 500, 1000, 2500];

      this.amountButton = this.countryCode.toUpperCase() === 'TH' ? thAmountButton : [...this.defaultAmountButton];

      if (!['TH', 'ID', 'MY'].includes(this.countryCode.toUpperCase())) {
        this.amountButton[0] = this.depositLimit.min < 50 ? 30 : 50;
      }
    }

    const minDeposit = this.countryCode.toUpperCase() === 'ID' ? +(this.depositLimit.min * 1000) : +this.depositLimit.min;
    const maxDeposit = this.countryCode.toUpperCase() === 'ID' ? +(this.depositLimit.max * 1000) : +this.depositLimit.max;

    const validators = [
      Validators.required,
      ...(minDeposit !== 0 ? [Validators.min(minDeposit)] : []),
      ...(maxDeposit !== 0 ? [Validators.max(maxDeposit)] : [])
    ];

    this.form.controls['amount'].setValidators(validators);
    this.form.controls['amount'].updateValueAndValidity();
  }

  async onUploadFileMulti(event: any) {
    this.uploadInProgress = true;
    const file: File = event.target.files[0];
    if (file == null)  {
      this.uploadInProgress = false;
      return;
    }
    var compressedFile: any;
    //Only image & file size greater than 2MB will need to compress
    if (file.type != "application/pdf" && file.size >= 2 * 1048576) {
      // Compress the file
      this.isCompressed = true;
      compressedFile = await this.fileCompression.transform(file);
    }
    if (compressedFile || this.isCompressed == false) {
      const formData = new FormData();
      this.fileName = compressedFile ? compressedFile.name : file.name;
      formData.append('files', compressedFile ? compressedFile : file);
      formData.append('type', this.dropdownService.uploadTypes[2]);
      this.uploadHttpService.upload(formData).pipe(
        tap((res: any) => {
          this.uploadInProgress = false;
          this.uploaded_receipts.push({
            path: res[0].indexOf('.pdf') >= 0 ? this.sanitizer.bypassSecurityTrustResourceUrl(res[0]) : res,
            isPdf: res[0].indexOf('.pdf') >= 0 ? true : false,
          });
          this.uploaded_receipts_form.push(res[0]);
          this.form.patchValue({
            receipt_path: this.uploaded_receipts_form
          });
          this.form.get('receipt_path').markAsDirty();
          this.form.markAllAsTouched();
          this.myFileInput.nativeElement.value = "";
      }),
        catchError((error) => {
          this.uploadInProgress = false;
          this.myFileInput.nativeElement.value = "";
        throw error;
        })
      ).subscribe();
    } else {
      this.isCompressed = false;
      this.uploadInProgress = false;
    }
    event.target.value = null;
  }

  onRemoveFileMulti(idx?) {
    if( idx !== undefined ) {
      this.uploaded_receipts.forEach((value,index)=>{
        if(index==idx) this.uploaded_receipts.splice(index,1);
      });
      this.uploaded_receipts_form.forEach((value,index)=>{
        if(index==idx) this.uploaded_receipts_form.splice(index,1);
      });
    } else {
      this.uploaded_receipts = [];
      this.uploaded_receipts_form = [];
    }
    this.form.patchValue({
      receipt_path: this.uploaded_receipts_form
    });
    this.form.get('receipt_path').markAsDirty();
    this.form.markAllAsTouched();
  }

  onConfirm() {
    if (this.isBankTransfer) {
      this.formInit();
      this.bankAccount = {
        name: '',
        number: null
      };
    }
  }

  onOfflineBank() {
    $('body').toggleClass("side-panel-open");
    this.offlinebankButtonClass = !this.offlinebankButtonClass;
  }

  onSave(event: Event) {
    event.preventDefault();
    if (sessionStorage.getItem('member_bank_accounts') === "[]" || sessionStorage.getItem('member_bank_accounts') === null) {
      this.openDialogBy(BankWrapperModalComponent, true);
    } else {
      this.disableButton = true;
      this.submitted = true;
      this.checkValidity = true;
      if (this.form.valid) {
        this.loadingBar.start();
        const data = {
          ...this.form.value
        };
        Object.keys(data).forEach((key) => {
          if (data[key] !== '' && data[key] !== null && data[key] !== undefined) {
            data[key] = key === 'datetime'
              ? moment(data[key]).utc().format('YYYY-MM-DD HH:mm:ss')
              : data[key];
          } else {
            delete data[key];
          }
        });

        const paymentGatewayCode = this.selectedPaymentGateway.code;
        const isTwoStepPG = this.twoStepPaymentPGs.includes(paymentGatewayCode);

        if (!this.isBankTransfer && !isTwoStepPG) {
          localStorage.setItem('depositData', JSON.stringify(data));
          this.router.navigate([]).then(() => window.open('/processing-transaction', '_blank'));
          this.loadingBar.complete();
          this.disableButton = false;
          this.checkValidity = false;
          this.onReset();
        } else {
          this.portalDepositHttpService.add(data).subscribe((res) => {
            this.loadingBar.complete();
            this.disableButton = false;
            this.checkValidity = false;
            this.isSuccess = this.portalDepositHttpService.isSuccess;
            if (this.isSuccess) {
              if (isTwoStepPG) {
                this.stepTwoDeposit = {
                  ...res.data.deposit,
                  topBarTitle: `Deposit (${this.methodSelected.name}: ${this.selectedPaymentGateway.channel_name_short})`,
                  formatted_expiry_date: this.formatUTCDate(res.data.deposit.expiry_date),

                  // if possible, for two-step payment in the future may standardize the data needed group under the field "stepTwoData"
                  stepTwoData: {
                    ...res.data.instructions
                  }
                };

                this.twoStepDepositDataService.storeLocalStorageForStepTwo({
                  'selectedMethodName': this.methodSelected.name, // actually this is not needed in MUP, but I just keep it to tally with UP
                  'selectedPaymentGateway': this.selectedPaymentGateway,
                  'stepTwoDeposit': this.stepTwoDeposit,
                });
                this.goToStepTwoPayment(this.stepTwoDeposit);
              } else {
                setTimeout(() => {
                  this.router.navigate(['/member/history/transaction']);
                }, 500);
              }
            }
          });
        }
      }
    }
  }

  goToStepTwoPayment(stepTwoDeposit) {
    this.currentStep = 2;

    if (this.selectedPaymentGateway.code === this.GATEWAY_PDXQR) {
      this.pgQrCodeSrc = "data:image/png;base64," + stepTwoDeposit.stepTwoData?.qrCodeDisplayImage;
    }

    if (this.selectedPaymentGateway.code === this.GATEWAY_PDXVA) {
      this.pdxvaStepTwoData = stepTwoDeposit?.stepTwoData;
    }

    // start timer countdown
    const countdownDuration = this.getCountdownDuration(this.stepTwoDeposit.expiry_date);
    this.startCountdown(countdownDuration);

    this.eventEmitterService.onGoToDepositStepTwoUpdateTitle(stepTwoDeposit.topBarTitle);
  }

  onSelectAmount(selectedAmount: number) {
    if (this.countryCode.toUpperCase() === 'ID') {
      this.IDRAmount = selectedAmount.toString();
    }
    this.form.patchValue({ amount: selectedAmount });
  }

  // ONLY IDR WILL TRIGGER THIS FUNCTION
  onInputAmount() {
    this.form.patchValue({ amount: +this.IDRAmount });
  }

  onOtherAmount() {
    this.form.patchValue({ amount: null });
    of(null).pipe(
      delay(0), tap(() => this.focusInput.first.nativeElement.focus()
      )).subscribe();
  }

  selectBank(bank: any) {
    this.showAmountSection = true;
    if (bank !== undefined && bank.showBank != false) {
      this.bankSelected = bank;
      this.showBanks = null;

      if(this.selectedMethod === 'EWT1' || this.selectedMethod === 'BOT1') {
        this.qrImg = bank.qr_image;
      }

      if (this.selectedMethod === 'BOT1' || this.selectedMethod === 'EWT1') {
        this.form.patchValue({
          merchant_bank_id: bank.id,
        });
        this.selectedBank = bank.id;

      }

      if (this.selectedPaymentGateway !== null && this.hasPaymentGatewayBanks(this.selectedPaymentGateway.code)) {
        this.selectedPGBank = bank.code;
        this.form.patchValue({
          bank_code: bank.code,
        });
      } else {
        this.bankAccount = {
          name: bank.account_name,
          number: bank.account_number
        };
      }
    }
  }

  onSelectDropdownAmount(item) {
    this.form.patchValue({
      amount: item.value
    });
  }

  onDeSelectDropdownAmount(item) {
    this.selectedAmount = null;
    this.form.patchValue({
      amount: ''
    });
  }

  onDeSelectChannelDropdown() {
    this.selectedPaymentGateway = { ...this.emptyPaymentGateway };
    this.showAmountSection = false;
  }

  onShowBanks() {
    this.showBanks = true;
  }

  onShowTokens() {
    this.showTokens = true;
  }

  onShowNetwork() {
    this.showNetwork = true;
  }

  onShowMethods() {
    this.methodSelected = null;
    this.showMethods = true;
    if ((this.selectedMethod === 'BOT1' || this.selectedMethod === 'EWT1')) {
      this.slider = {
        bankHref: '',
        bankId: '',
        receiptHref: '',
        receiptId: ''
      };
    } else {
      this.slider = {
        bankHref: '#collapseBank',
        bankId: 'collapseBank',
        receiptHref: '#collapseReceipt',
        receiptId: 'collapseReceipt'
      };
    }
  }

  onToggleAmountButtons(show: boolean, timeout = 0) {
    setTimeout(() => {
      this.showAmountButtons = show;
      if (this.showAmountButtons) {
        of(null).pipe(
          delay(0), tap(() => this.focusInput.first.nativeElement.focus()
          )).subscribe();
      }
    }, timeout)
  }

  onCopyText(val: string, type: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    if (type === 'name') {
      this.tooltipName.show();
      setTimeout(() => this.tooltipName.hide(), 2000);
    } else if (type === 'address') {
      this.tooltipAddress.show();
      setTimeout(() => this.tooltipAddress.hide(), 2000);
    } else {
      this.tooltipNumber.show();
      setTimeout(() => this.tooltipNumber.hide(), 2000);
    }
  }

  onSelectPaymentMethod(method: any, fromLastOpton?: boolean) {
    this.onResetCryptoInfo();
    this.onResetCryptoFields();
    this.selectedPaymentGateway = { ...this.emptyPaymentGateway };
    this.checkValidity = false;
    if (this.countryCode.toUpperCase() === 'ID') {
      this.amountButton = [10, 25, 100, 300, 500, 1000];
    }

    if (this.countryCode.toUpperCase() === 'TH') {
      if (method.code === 'BOT1' || method.code === 'EWT1') {
        this.amountButton = [100, 200, 300, 500, 1000, 2500];
      } else {
        this.amountButton = [500, 1000, 1500, 2000, 2500, 3000];
      }
    }

    if (this.countryCode.toUpperCase() === 'MY') {
      if (method.code === 'BOT1' || method.code === 'EWT1') {
        this.amountButton = [...this.defaultAmountButton];
      } else {
        this.amountButton = [50, 100, 200, 300, 500, 1000];
      }
    }

    if (method.code === 'BOT1' || method.code === 'EWT1' || this.isPaymentGatewayMethod(method.code)) {
      this.showAmountSection = false;
    } else {
      this.showAmountSection = true;
    }
    this.depositLimit = null;
    this.bankSelected = null;
    this.showBanks = false;
    this.showTokens = false;
    this.showPaymentGateway = false;
    this.showMethods = null;
    this.methodSelected = method;
    this.selectedMethod = method.code;
    this.selectedBank = null;
    this.selectedPGBank = null;
    this.showChannelDropdown = method.show_channel;
    this.isBankTransfer = false;
    this.displayBankDropdown = true;

    if (method.code !== 'BOT1' && method.code !== 'EWT1' && this.uploaded_receipts_form.length > 0) {
      this.onRemoveFileMulti();
      this.updateValidation(false);
      this.form.controls['bank_code'].clearValidators();
      this.form.controls['payment_gateway_code'].clearValidators();
      this.form.controls['payupiid'].clearValidators();
    }
    if ((method.code === 'BOT1' || method.code === 'EWT1') && this.form.value.receipt_path === null) {
      this.disableButton = true;
      this.updateValidation(true);
      this.form.controls['payment_gateway_code'].clearValidators();
      this.form.controls['bank_code'].clearValidators();
      this.form.controls['payupiid'].clearValidators();
    }

    this.form.controls['payment_gateway_code'].updateValueAndValidity();
    this.form.controls['bank_code'].updateValueAndValidity();
    this.form.controls['payupiid'].updateValueAndValidity();

    if (method.code !== 'BOT1' && method.code !== 'EWT1') {
      this.disableButton = false;
      this.updateValidation(false);
    }

    if (this.isPaymentGatewayMethod(method.code)) {
      if (!fromLastOpton) {
        this.bankHttpService.getPaymentGatewayList(method.code).subscribe(
          res => {
            this.dropdown.paymentGateways = res;

            if (method.show_channel) {
              this.dropdown.paymentGateways = this.dropdown.paymentGateways.map((pg, idx) => ({
                ...pg,
                channel_logo: `channel-${idx + 1}-${this.languageCode}.png`,
                channel_name: `Channel ${idx + 1}`,
                channel_name_short: `CH ${idx + 1}`,
              }));
            }

            this.onShowPaymentGateway();
            this.form.controls['payment_gateway_code'].setValidators([Validators.required]);
            this.form.controls['payment_gateway_code'].updateValueAndValidity();
            if (method.code === 'UPI') {
              this.form.controls['payupiid'].setValidators([Validators.required]);
              this.form.controls['payupiid'].updateValueAndValidity();
            }
            if(this.dropdown.paymentGateways.length == 1) {
              this.onSelectPaymentGateway(this.dropdown.paymentGateways[0]);
            }
          }
        );
      }
    } else {
      this.form.controls['payment_gateway_code'].clearValidators();
      this.form.controls['payment_gateway_code'].updateValueAndValidity();
      this.form.controls['payupiid'].clearValidators();
      this.form.controls['payupiid'].updateValueAndValidity();
    }
    this.form.patchValue({
      merchant_bank_id: null,
      bank_code: null,
      payment_method_code: this.selectedMethod,
      amount: null,
      payment_gateway_code: null,
      payupiid: null,
    });
    this.bankAccount = {
      name: null,
      number: null
    };
    this.showPreloader = true;

    if (this.selectedMethod === 'BOT1' || this.selectedMethod === 'EWT1') {
      if (this.memberMerchantBank !== null) {

        this.memberMerchantBank.forEach((item, index) => {
          this.gmt = JSON.parse(sessionStorage.getItem('member_merchant_bank'))[index]['gmt'];
          this.isBankMaintenance(item, this.gmt);
          if(item['showBank'] == true && this.merchantBankItemsDisplay == false) {
            this.merchantBankItemsDisplay = true;
          }
        });
        sessionStorage.setItem('member_merchant_bank', JSON.stringify(this.memberMerchantBank));

        this.merchantBankDeposits$ = this.memberMerchantBank;
        this.onShowBanks();
        this.showPreloader = false;
      } else {
        this.dropdown.merchantBankDeposits.subscribe(res => {
          res.forEach((item, index) => {
            this.gmt = JSON.parse(sessionStorage.getItem('member_merchant_bank'))[index]['gmt'];
            this.isBankMaintenance(item, this.gmt);
            if(item['showBank'] == true && this.merchantBankItemsDisplay == false) {
              this.merchantBankItemsDisplay = true;
            }
          });

          sessionStorage.setItem('member_merchant_bank', JSON.stringify(res));
          this.memberMerchantBank = res;
          this.showPreloader = false;
          this.merchantBankDeposits$ = res;
          this.onShowBanks();
        });
      }
    }
    if (method.redirect === 0) {
      this.isBankTransfer = true;
    }

    if(method.code === 'BOT1') {
      this.merchantBankDeposits$ = this.memberMerchantBank.filter(x => x.bank_type == 1);
    }
    else if(method.code === 'EWT1') {
      this.merchantBankDeposits$ = this.memberMerchantBank.filter(x => x.bank_type == 3);
    }

    this.qrImg = null;

    if (method.code === 'EWT1' && this.merchantBankDeposits$.length === 1) {
      this.selectBank(this.merchantBankDeposits$[0]);
    }
  }

  onShowPaymentGateway() {
    this.showPaymentGateway = true;
  }

  onChannelSelectChange(code: string) {
    this.selectedPaymentGateway = this.dropdown.paymentGateways.find(x => x.code === code);
    this.onSelectPaymentGateway(this.selectedPaymentGateway);
  }

  onSelectPaymentGateway(item: any) {
    this.showAmountSection = !this.hasPaymentGatewayBanks(item.code);
    this.onGetLimit(item.id, item.merchant_bank_id);
    this.selectedPaymentGateway = item;
    this.showPaymentGateway = null;
    this.bankSelected = null;
    this.showBanks = false;

    if (item.channel_name) {
      this.selectedChannel = [{ ...item }];
    }

    this.form.patchValue({
      payment_gateway_code: item.code,
      bank_code: null
    });
    if (item.redirect === 0) {
      this.isBankTransfer = true;
    } else {
      this.isBankTransfer = false;
    }

    const itemCode = item.code === 'EZP' || item.code === 'EZP2' ? 'EZP' : item.code;
    this.dropdown.banks = this.bankHttpService.getPaymentGatewayBank(itemCode) || null;

    // currently feature only patch for method QP3
    if (item.code === 'EZP2' && this.selectedMethod == 'QP3') {
      this.displayBankDropdown = false;
    } else {
      this.displayBankDropdown = true;
    }

    if (this.countryCode.toUpperCase() === 'ID') {
      if (item.code === 'H2P') {
        this.amountButton = [50, 300, 400, 500, 800, 1000];
      } else {
        this.amountButton = [...this.defaultAmountButton];
      }
    }

    if (this.hasPaymentGatewayBanks(item.code)) {
      this.dropdown.banks.subscribe(res => {
        this.pgBankDropdown = res;
        this.showPreloader = false;

        //prepare bank list for display (deposit)
        this.pgBankDropdown.forEach(item => {
          this.gmt = item.gmt;
          this.isBankMaintenance(item, this.gmt);
          if(item['showBank'] == true && this.pgItemsDisplay == false) {
            this.pgItemsDisplay = true;
          } 
        });

        // pick first item if bank dropdown only one item
        if ((item.code === 'EZP2' && this.selectedMethod == 'QP3') || this.selectedPaymentGateway.code == 'VTPAYQR') {
          this.autoSelectBankDropdown(this.pgBankDropdown);
        }
      });
    }

    this.form.controls['bank_code'].setValidators(this.hasPaymentGatewayBanks(item.code) ? [Validators.required] : []);
    this.form.controls['bank_code'].updateValueAndValidity();

    if (item.code == 'WP') {
      this.form.patchValue({
        payupiid: null
      });
      this.form.controls['payupiid'].setValidators([Validators.required]);
      this.form.controls['payupiid'].updateValueAndValidity();
    }

    this.onResetCryptoFields();
    if (item.code === 'C2P') {
      this.onGetCryptoInfo();
    }
    
  }

  private autoSelectBankDropdown(bank: any) {
    // more than one option display bank as usual
    if (bank && bank.length > 1) {
      this.displayBankDropdown = true;
      return;
    }

    // only one option auto select
    if (bank && bank.length == 1) {
      this.selectBank(bank[0]);
    }

    // hide bank when option only one or none
    this.displayBankDropdown = false;
  }

  onReset() {
    this.selectedBank = null;
    this.selectedPGBank = null;
    this.selectedMethod = null;
    this.onShowMethods();
    this.selectedPaymentGateway = { ...this.emptyPaymentGateway };
    this.showAmountSection = false;
  }

  onSelectTheSame(method?: any) {
    this.expandClass = 'collapse show';
    this.methodSelected = method;
    this.showMethods = null;
  }

  formatUTCDate(date: string) {
    return moment(date).utcOffset(this.userGmtOffset).format('YYYY-MM-DD HH:mm:ss');
  }

  private openDialogBy(componentRef: any, is_deposit?: boolean) {
    this.dialog.open(componentRef, {
      width: '800px',
      data: {
        is_deposit: is_deposit
      }
    });
  }

  isPaymentGatewayMethod(selectedMethod: string) {
    return (
      selectedMethod === "QP1" ||
      selectedMethod === "QP3" ||
      selectedMethod === "EW1" ||
      selectedMethod === "QRPP" ||
      selectedMethod === "QRDN" ||
      selectedMethod === "QRIS" ||
      selectedMethod === "UPI" ||
      selectedMethod === "FPX" ||
      selectedMethod === "PNQR" ||
      selectedMethod === "BTG" ||
      selectedMethod === "PNVA" ||
      selectedMethod === "CRY"
    );
  }

  showGatewayOptions() {
    return this.displayBankDropdown && 
    (
      this.selectedMethod === 'BOT1' ||
      this.selectedMethod === 'EWT1' ||
      (this.selectedPaymentGateway !== null &&
      this.hasPaymentGatewayBanks(this.selectedPaymentGateway.code))
    );
  }

  hasPaymentGatewayBanks(code: string) {
    const gatewayCodes = [
      "FP",
      "IP",
      "TP",
      "TPDNQR",
      "EZP",
      "EZPFPX",
      "EZPQR",
      "EZPQRPP",
      "H2PQRIS",
      "YPAY",
      "SCPFPX",
      "VTPAY",
      "VTPAYVA",
      "FPAYFPX",
      "DGPAY",
      "MTPAY"
    ];

    return gatewayCodes.includes(code);
  }

  /**
   * @deprecated
   *
   * Note:
   * DON'T USE THIS FUNC, use hasPaymentGatewayBanks() above first whenever possible.
   * This func is leave as a backup in case positioning below breaks on other PG, which is quite unlikely imo.
   * In fact, this func is only used to determine positioning, but that positioning should be based on
   * banks section, not amount selection
   *
   * @param selectedPaymentGateway 
   * @returns 
   */
  gatewayHasAmountSelection(selectedPaymentGateway) {
    const gatewayCodes = [
      "FP",
      "IP",
      "EZP",
      "EZP2",
      "EZPFPX",
      "EZPQR",
      "EZPQRPP",
      "H2PQRIS",
      "TP",
      "SCPFPX",
      "VTPAY",
      "VTPAYVA",
    ];

     return gatewayCodes.includes(selectedPaymentGateway.code);
  }

  getDepositAmountSectionPosition() {
    let position;

    const hasBankDropdown = this.displayBankDropdown &&
      this.selectedPaymentGateway !== null &&
      this.hasPaymentGatewayBanks(this.selectedPaymentGateway.code);

    if (hasBankDropdown) {
      position = this.dropdown.paymentGateways.length > 1
        ? 4
        : 3;
    } else {
      if (this.isPaymentGatewayMethod(this.selectedMethod)) {
        position = (this.dropdown.paymentGateways.length > 1)
          ? 3
          : 2;
      } else {
        position = 3;
      }
    }

    return position;
  }

  private updateValidation(required: boolean) {
    this.form.controls['receipt_path'].setValidators(required ? [Validators.required] : null);
    this.form.controls['receipt_path'].updateValueAndValidity();
    this.form.controls['merchant_bank_id'].setValidators(required ? [Validators.required] : null);
    this.form.controls['merchant_bank_id'].updateValueAndValidity();
  }

  private formInit() {
    const formAmountValidatorOption = !this.submitted ? [Validators.required] : [];
    const formBankCodeValidatorOption = ['FP', 'IP', 'TP', 'EZP', 'EZP2', 'EZPFPX', 'EZPQR', 'EZPQRPP', 'H2PQRIS', 'YPAY', 'SCPFPX', 'VTPAY', 'VTPAYVA', 'DGPAY', 'MTPAY'].includes(this.selectedPaymentGateway?.code) ? [Validators.required] : [];
    const formPaymentGatewayValidatorOption = this.selectedMethod === 'QP1' || this.selectedMethod === 'QP3' || this.selectedMethod === 'UPI' ? [Validators.required] : [];
    const formUPIValidatorOption = this.selectedMethod === 'UPI' ? [Validators.required] : [];

    this.form = new FormGroup({
      merchant_bank_id: new FormControl(null, []),
      bank_code: new FormControl(null, formBankCodeValidatorOption),
      amount: new FormControl(null, formAmountValidatorOption),
      receipt_path: new FormControl(null, []),
      payment_method_code: new FormControl(null, [Validators.required]), // TODO: Static value in DB
      bank_selected: new FormControl(null),
      payment_gateway_code: new FormControl(null, formPaymentGatewayValidatorOption),
      payupiid: new FormControl(null, formUPIValidatorOption)
    });
  }

  @HostListener('window:resize', [])
  onResize() {
    if (window.innerHeight < this.windowInnerHeight) {
      window.scroll(0, 160);
    }
  }

  onBankMaintenanceHours(event: any){
    const nativeElement = this.bankMaintenanceHourTemplate.nativeElement;
    nativeElement.style.cssText = 'visibility:visible';

    Swal.fire({
      title: this.translateService.instant('Bank Maintenance Hours'),
      html: this.bankMaintenanceHourTemplate.nativeElement,
      confirmButtonText: this.translateService.instant('Close'),
      showCloseButton: true,
      customClass:{
        title: 'bank-maintenance-hour-title',
        container: 'bank-maintenance-hour-container',
        popup: 'bank-maintenance-hour-popup',
      }
    });
  }

  isBankMaintenance(item:any, gmt:any){
    item['showBank'] = true;

    if(gmt != '' && (item['availability_control'] == 1 || item['availability_control'] == 3)){
      let hours_minutes = gmt.split(':');
      let posOrNeg = (hours_minutes[0][0] == '-')? '-': '+'; // get positive or negative
      let hours = hours_minutes[0];
      let minutes = hours_minutes[1];

      // get the user current time
      this.currentTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg+minutes), 'minutes');
      this.currentTime = moment(this.currentTime.format('HH:mm'), 'HH:mm');
      this.currentDayOfWeek = this.currentTime.clone().locale('en').format('dddd').toLowerCase();
      this.currentDate = this.currentTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // get the user yesterday time
      this.yesterdayTime = moment.utc().add(parseInt(hours), 'hours').add(parseInt(posOrNeg+minutes), 'minutes');
      this.yesterdayTime = moment(this.yesterdayTime.format('HH:mm'), 'HH:mm').subtract(1, "days");
      this.yesterday = this.yesterdayTime.clone().locale('en').format('dddd').toLowerCase();
      this.yesterdayDate = this.yesterdayTime.clone().locale('en').format('YYYY-MM-DD').toLowerCase();

      // if enable_all_day is on
      if(item['enable_all_day'] == true){
        for(let i = 0; i < item['all_day'].length; i++){
          // get the maintenance hour time (from & to)
          let all_day = item['all_day'][i];

          // since all day, check yesterday overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-01 23:00 -> 2024-02-02 06:00
          let yesterday_from = moment(moment.utc(`${this.yesterdayDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let yesterday_to = moment(moment.utc(`${this.yesterdayDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(yesterday_to < yesterday_from){
            yesterday_to = yesterday_to.add(1, 'days');
          }

          console.log(yesterday_from.toString())
          console.log(yesterday_to.toString());

          // check if the current bank is in maintenance
          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(yesterday_from, yesterday_to) || this.currentTime.isSame(yesterday_from) || this.currentTime.isSame(yesterday_to)){
            item['showBank'] = false;
            break;
          }

          // since all day, check today overlap case
          // eg: today is 2024-02-02 00:02
          // it compare 2024-02-02 23:00 -> 2024-02-03 06:00
          let today_from = moment(moment.utc(`${this.currentDate} ${all_day.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let today_to = moment(moment.utc(`${this.currentDate} ${all_day.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(today_to < today_from){
            today_to = today_to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(today_from, today_to)  || this.currentTime.isSame(today_from) || this.currentTime.isSame(today_to)){
            item['showBank'] = false;
            break;
          }
        }
      }

      // if yesterday checkbox is enabled
      if (item['enable_'+this.yesterday] == true){
        for(let i = 0; i < item[this.yesterday].length; i++){
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.yesterday][i];

          let from = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.yesterdayDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(to < from){
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)){
            item['showBank'] = false;
            break;
          }
        }
      }

      // if today checkbox is enabled
      if (item['showBank'] == true && item['enable_'+this.currentDayOfWeek] == true){
        for(let i = 0; i < item[this.currentDayOfWeek].length; i++){
          // get the maintenance hour time (from & to)
          let dayOfWeek = item[this.currentDayOfWeek][i];

          let from = moment(moment.utc(`${this.currentDate} ${dayOfWeek.from}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');
          let to = moment(moment.utc(`${this.currentDate} ${dayOfWeek.to}`, "YYYY-MM-DD HH:mm").format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm');

          // get tomorrow date for to if to time is smaller than from time
          if(to < from){
            to = to.add(1, 'days');
          }

          // check if the current bank is in maintenance
          if(this.currentTime.isBetween(from, to) || this.currentTime.isSame(from) || this.currentTime.isSame(to)){
            item['showBank'] = false;
            break;
          }
        }
      }
    }
  } 

  // fallback generic bank image
  onImgError(event){
    event.target.src ='/assets/images/generic-bank.jpg';
  }

  onSelectToken(selectedToken: any) {
    if( selectedToken == this.selectedToken ) {
      this.showTokens = null;
      return;
    }

    this.showNetwork = false;
    this.selectedNetwork = [];
    this.onResetCryptoInfo();
    
    this.selectedToken = selectedToken;
    const matchingTokens = this.availableTokens.filter(token => token.token === selectedToken.token);
    this.networkDropdownList = matchingTokens.map(token => ({ id: token.id, token: token.token, network: token.network }));
    if (this.networkDropdownList.length == 1) {
      this.onSelectNetwork(this.networkDropdownList[0].network);
    }
    this.showTokens = null;
  }

  onSelectNetwork(selectedNetwork) {
    let targetedNetwork = this.networkDropdownList.filter(token => token.network === selectedNetwork);
    if( selectedNetwork == this.selectedNetwork ) {
      this.showNetwork = null;
      return;
    }

    this.selectedNetwork = targetedNetwork[0];
    this.onResetCryptoInfo();

    const selectedCrypto = this.availableTokens.filter(token => token.token === this.selectedToken.token && token.network === this.selectedNetwork.network);
    if( selectedCrypto.length > 0 ) {
      this.selectedCryptoInfo = {
        'address':  selectedCrypto[0].address || '',
        'estimated_credit': selectedCrypto[0].Estimate_Given_Credit || '',
        'check_status_url': selectedCrypto[0].check_status_url || ''
      };
      this.showNetwork = null;
    } else {
      this.showNetwork = false;
    }
  }

  onGetCryptoInfo() {
    this.loadingBar.start();
    this.checkValidity = true;
    this.portalDepositHttpService.getCryptoInfo(`?code=${this.selectedPaymentGateway.code}`).subscribe(res => {
      this.loadingBar.complete();
      if( res.success === true && res.data ) {
        this.availableTokens = JSON.parse(JSON.stringify(res.data));
        this.filteredTokens = Array.from(new Set(this.availableTokens.map(token => token.token))).map(token => {
          return this.availableTokens.find(t => t.token === token);
        });
      }
      this.checkValidity = false;
    });
    
  }

  onResetCryptoFields() {
    this.selectedToken = [];
    this.selectedNetwork = [];
    this.showTokens = false;
    this.showNetwork = false;
    this.availableTokens = [];
    this.filteredTokens = [];
    this.networkDropdownList = [];
  }

  onResetCryptoInfo() {
    this.selectedCryptoInfo = null;
  }

  onCheckStatus(address: any) {
    if( !this.selectedCryptoInfo || !this.selectedCryptoInfo.check_status_url ) {
      return;
    }
    window.open(this.selectedCryptoInfo.check_status_url, '_blank');
    window.location.href = '/member/history/transaction';
  }

  onSaveQR(parent: any) {
    let parentElement = null

    if (this.elementType === "canvas") {
      // fetches base 64 data from canvas
      parentElement = parent.qrcElement.nativeElement
        .querySelector("canvas")
        .toDataURL("image/png")
    } else if (this.elementType === "img" || this.elementType === "url") {
      // fetches base 64 data from image
      // parentElement contains the base64 encoded image src
      // you might use to store somewhere
      parentElement = parent.qrcElement.nativeElement.querySelector("img").src
    } else {
      alert("Set elementType to 'canvas', 'img' or 'url'.")
    }

    if (parentElement) {
      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = "angularx-qrcode"
      link.click()
    }
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }


  private padNumber(number) {
    return number.toString().padStart(2, '0');
  }
}
