import { Auth } from '@core/models/auth.model';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthActions } from './auth-action.types';

export interface AuthState {
  user: Auth;
}

const initialAuthState: AuthState = {
  user: undefined
};

export const reducer = createReducer(

  initialAuthState,

  on(AuthActions.login, (state, action) => {
    return {...state, user: action };
  }),

  on(AuthActions.changeProfile, (state, action) => {
    localStorage.setItem('profile', action.profile.name);
    return {
      ...state,
      user: {
        ...state.user,
        user: {
          ...action.profile,
          name: action.profile.name,
          username: action.profile.username,
          member_group: {
            name: action.profile.member_group.name,
            image: action.profile.member_group.image,
            trial: action.profile.member_group.trial
          }
        }
      }
    };
  }),

  on(AuthActions.logout, (state, action) => {
    return {...state, changeProfileNameuser: {}};
  })
);

export function authReducer(state: AuthState, action: Action) {
  return reducer(state, action);
}
