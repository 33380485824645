// Angular
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Service
import { WebsocketService } from './websocket.service';
import { environment } from '@env/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { ApiResponse } from '@core/models/api-response.model';
import { map, tap } from 'rxjs/operators';
import { RequestReceipt } from '@core/models/request-receipt.model';
import { EventEmitterService } from '@core/services/event-emitter.service';

@Injectable({
  providedIn: 'root'
})
export class RequestReceiptHttpService {

  static requestReceipt: RequestReceipt = null;

  newRequestIncoming = new Subject();
  messages$ = new Subject<any[]>();

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private eventEmitterService: EventEmitterService,
    private websocketService: WebsocketService,
  ) {}

  async init(memberAccountId: number) {
    await this.getRequestReceipt();
    this.requestReceiptWebsocket(memberAccountId);
  }

  update(depositId: number, data: { receipt_path?: string, status: number }) {
    return this.http.put<ApiResponse>(`/deposit/receipt/${depositId}`, data).pipe(
      tap(res => {
        this.messages$.next(res.message);
        RequestReceiptHttpService.requestReceipt = null;
        this.newRequestIncoming.next();
      }),
      map(res => res)
    );
  }

  private getRequestReceipt() {
    return this.http.get<ApiResponse>(`/deposit/receipt/request`).pipe(
      map(res => res.data.rows[0]),
      tap((res: RequestReceipt) => {
        if (res) {
          res.reason = res.reason?.replace('Reason: ', '');
          RequestReceiptHttpService.requestReceipt = res;
          setTimeout(() => {
            this.newRequestIncoming.next();
          }, 1000); // Delay 1 seconds for better UX
        }
      })
    ).toPromise();
  }

  private requestReceiptWebsocket(memberAccountId: number) {
    this.websocketService
      .getChannel('request-receipt-channel.' + memberAccountId)
      .listen(`.RequestReceiptEvent`, (data: RequestReceipt) => {
        if (data.status == 7) {
          data.reason = data.reason?.replace('Reason: ', '');
          RequestReceiptHttpService.requestReceipt = data;
        } else if (data.status == 1) {
          RequestReceiptHttpService.requestReceipt = null;
          // To update member_merchant_bank session storage
          this.eventEmitterService.onUpdateMemberMerchantBankSessionEmitter();
        } else {
          RequestReceiptHttpService.requestReceipt = null;
        }
        this.newRequestIncoming.next();
      });
  }

}
