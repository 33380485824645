<ng-container *ngIf="onDialog">
  <div cdkFocusInitial class="modal show sign-in" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-body">
        <div class="modal-content modal-bg modal-blue-bg">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">{{ 'Bank Details' | translate }} </h4>
            <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
              (click)="onCloseDialog()">×</button>
          </div>
          <div class="bd-bold"></div>
            <div class="user-input modal-body pb-0" *ngIf="is_deposit && (memberBankAccounts == null || memberBankAccounts.length == 0)">
              <label>{{ 'Please update your bank account detail before submitting a deposit request' | translate }}</label>
            </div>
            <div class="user-input modal-body pb-0" *ngIf="is_transfer && (memberBankAccounts == null || memberBankAccounts.length == 0)">
              <label>{{ 'Please update your bank account detail before submitting a transfer request' | translate }}</label>
            </div>
            <div class="user-input modal-body pb-0" *ngIf="is_withdraw && (memberBankAccounts == null || memberBankAccounts.length == 0)">
              <label>{{ 'Please update your bank account detail before submitting a withdraw request' | translate }}</label>
            </div>
            <div class="user-input bankaccount-input p-1rem p-b-0">
              <label>{{ 'Payment Method' | translate }} <span class="required">*</span></label>
              <div [ngClass]="{'required-dropdown': checkValidity && form.controls.bank_type.errors}">
                <angular2-multiselect [data]="availablePaymentMethod" [(ngModel)]="selectedPaymentMethod" [settings]="paymentMethodDropdownSettings"
                  (onSelect)="onSelectPaymentMethod(selectedPaymentMethod[0].id)" (onDeSelect)="OnDeSelectPaymentMethod($event)" (onDeSelectAll)="OnDeSelectPaymentMethod($event)">
                </angular2-multiselect>
              </div>
            </div>
            <div class="user-input bankaccount-input p-1rem p-b-0">
              <label>{{ selectedBankType === 'E-Wallet' ? ('E-Wallet' | translate) : ('Bank' | translate) }} <span class="required">*</span></label>
              <div [ngClass]="{'required-dropdown': checkValidity && form.controls.bank_id.errors}">
                <angular2-multiselect [data]="bankDropdownList" [(ngModel)]="selectedBank" [settings]="bankDropdownSettings"
                  (onSelect)="onSelectBank($event)" (onDeSelect)="OnDeSelectBank($event)" (onDeSelectAll)="OnDeSelectBank($event)">
                </angular2-multiselect>
              </div>
            </div>
            <form [formGroup]="form" class="p-1rem bankaccount-input" id="bank-modal-form" name="bank-modal-form">
              <div class="user-input">
                <ng-container *ngIf="selectedBankCode === 'PAYNOW' && countryCode === 'SG'">
                  <div class="form-input-label">
                    <p>{{ 'Type' | translate }} </p>
                  </div>
                  <select class="form-control form-input-style mb-3" (change)="onSelectType($event.target.value)">
                    <option value="null" hidden>Please Select</option>
                    <option [value]="item.code" *ngFor="let item of payNowType">{{ item.name}}</option>
                  </select>
                  <ng-container *ngIf="selectedType !== undefined">
                    <ng-container *ngIf="selectedType === 'mobile'">
                      <label>{{ 'Mobile Number' | translate }}</label>
                      <input type="text" class="form-control m-b-10" formControlName="mobile_number" placeholder="{{ 'Enter Mobile Number' | translate }}"/>
                    </ng-container>
                    <ng-container *ngIf="selectedType === 'nric'">
                      <label>{{ 'NRIC/FIN' | translate }}</label>
                      <input type="text" class="form-control m-b-10" formControlName="nric_fin" placeholder="{{ 'Enter NRIC/FIN' | translate }}"/>
                    </ng-container>
                    <ng-container *ngIf="selectedType === 'uen'">
                      <label>{{ 'Unique Entity Name (UEN)' | translate }}</label>
                      <input type="text" class="form-control m-b-10" formControlName="unique_entity_name" placeholder="{{ 'Enter UEN' | translate }}"/>
                    </ng-container>
                    <ng-container *ngIf="selectedType === 'vpa'">
                      <label>{{ 'Virtual Payment Address (VPA)' | translate }}</label>
                      <input type="text" class="form-control m-b-10" formControlName="virtual_payment_address" placeholder="{{ 'Enter VPA' | translate }}"/>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="(selectedBankCode !== 'PAYNOW' && selectedBankCode) || countryCode !== 'SG'">
                  <label>{{ selectedBankType === 'E-Wallet' ? ('E-Wallet Account' | translate) : ('Bank Account' | translate) }} <span class="required">*</span></label>
                  <input *ngIf="userName !== null && userName !== ''" type="text" class="form-control m-b-10" [value]="userName" placeholder="{{ 'Account Name' | translate }}" readonly />
                  <input *ngIf="userName === null || userName === ''" type="text" class="form-control m-b-10 mb-1" formControlName="account_name" placeholder="{{ 'Account Name' | translate }}" [ngClass]="{'form-invalid': checkValidity && form.controls.account_name.errors && userName === null }" />
                  <input type="text" class="form-control mb-0" placeholder="{{ selectedBankType === 'E-Wallet'? ('E-Wallet Number' | translate) : ('Account Number' | translate) }}" formControlName="account_number" [ngClass]="{'form-invalid': checkValidity && form.controls.account_number.errors }" #focusInput/>
                  <label *ngIf="selectedBankType === 'E-Wallet' && countryCode != 'ID'" class="text-left fw-bold w-100 text-blue">{{ 'Please do not fill in mobile number' | translate }}</label>
                  <!-- <label *ngIf="selectedBankType === 'E-Wallet' && countryCode == 'ID'" class="text-left fw-bold w-100 text-blue">{{ 'Your e-wallet number (Premier) must be same with your registered contact number' | translate }}</label> -->
                  <div class="col-form-label" style="padding-left:0" *ngIf="bankFieldVisible === true">
                    <label>{{ 'Bank Name' | translate }}: <span class="text-danger"></span></label>
                  </div>
                  <div class="form-group" style="padding-left:0" *ngIf="bankFieldVisible === true">
                    <div class="row">
                      <div class="col-md-12">
                        <input class="form-control mb-1" formControlName="bank_name" type="text" [placeholder]="'Enter Your Bank Name' | translate" [ngClass]="{'form-invalid': checkValidity && form.controls.bank_name.errors}">
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            <div class="m-t-30">
              <button data-toggle="modal" [ngClass]="{'disabled': disabledButton}" [disabled]="disabledButton" class="btn-blue-gra" data-target="#warning-msg" (click)="onSubmit(this.selectedBank, false)">
                {{'Save' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!onDialog">
    <div class="transaction-container m-b-0">
      <!--Payment Method Selection-->
      <div class="form-input-label">
        <p>{{ 'Payment Method' | translate }} </p>
      </div>
      <div [ngClass]="{'required-dropdown': checkValidity && form.controls.bank_type.errors}">
        <angular2-multiselect [data]="availablePaymentMethod" [(ngModel)]="selectedPaymentMethod" [settings]="paymentMethodDropdownSettings" 
          (onSelect)="onSelectPaymentMethod(selectedPaymentMethod[0].id)" (onDeSelect)="OnDeSelectPaymentMethod($event)" (onDeSelectAll)="OnDeSelectPaymentMethod($event)">
        </angular2-multiselect>
      </div>
      <!--Bank Selection-->
      <div class="form-input-label mt-3">
        <p>{{ selectedBankType === 'E-Wallet' ? ('E-Wallet' | translate) : ('Bank' | translate) }}</p>
      </div>
      <div [ngClass]="{'required-dropdown': checkValidity && form.controls.bank_id.errors}">
        <angular2-multiselect [data]="bankDropdownList" [(ngModel)]="selectedBank" [settings]="bankDropdownSettings"
          (onSelect)="onSelectBank($event)" (onDeSelect)="OnDeSelectBank($event)" (onDeSelectAll)="OnDeSelectBank($event)">
        </angular2-multiselect>
      </div>
     <form [formGroup]="form" id="bank-form" name="bank-form" class="m-b-0">
      <ng-container *ngIf="selectedBankCode === 'PAYNOW' && countryCode === 'SG'">
        <div class="form-input-label mt-3">
          <p>{{ 'Type' | translate }} </p>
        </div>
        <select class="form-control form-input-style" (change)="onSelectType($event.target.value)">
          <option value="null" hidden>Please Select</option>
          <option [value]="item.code" *ngFor="let item of payNowType">{{ item.name}}</option>
        </select>

        <ng-container *ngIf="selectedType !== undefined">
          <ng-container *ngIf="selectedType === 'mobile'">
            <div class="form-input-label mt-3">
              <p>{{ 'Mobile Number' | translate }}</p>
            </div>
            <div class="input_container bank-acc">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10" placeholder="{{ 'Enter Mobile Number' | translate }}" formControlName="mobile_number" />
            </div>
          </ng-container>
          <ng-container *ngIf="selectedType === 'nric'">
            <div class="form-input-label mt-3">
              <p>{{ 'NRIC/FIN' | translate }}</p>
            </div>
            <div class="input_container bank-acc">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10" placeholder="{{ 'Enter NRIC/FIN' | translate }}" formControlName="nric_fin" />
            </div>
          </ng-container>
          <ng-container *ngIf="selectedType === 'uen'">
            <div class="form-input-label mt-3">
              <p>{{ 'Unique Entity Name (UEN)' | translate }}</p>
            </div>
            <div class="input_container bank-acc">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10" placeholder="{{ 'Enter UEN' | translate }}" formControlName="unique_entity_name" />
            </div>
          </ng-container>
          <ng-container *ngIf="selectedType === 'vpa'">
            <div class="form-input-label mt-3">
              <p>{{ 'Virtual Payment Address (VPA)' | translate }}</p>
            </div>
            <div class="input_container bank-acc">
              <input type="text" class="form-control form-control-sm form-input-style m-b-10" placeholder="{{ 'Enter VPA' | translate }}" formControlName="virtual_payment_address" />
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedBankCode && (selectedBankCode !== 'PAYNOW' || countryCode !== 'SG')">
        <!--Bank Account Input-->
        <div class="form-input-label mt-3">
          <p>{{ selectedBankType === 'E-Wallet' ? ('E-Wallet Account' | translate) : ('Bank Account' | translate) }}</p>
        </div>
        <div class="input_container bank-acc">
          <input *ngIf="userName !== null" type="text" class="form-control form-control-sm form-input-style m-b-10" [value]="userName" placeholder="{{ 'Account Name' | translate }}" readonly />
          <input *ngIf="userName === null" type="text" class="form-control form-control-sm form-input-style m-b-10" formControlName="account_name" placeholder="{{ 'Account Name' | translate }}" [ngClass]="{'form-invalid': checkValidity && form.controls.account_name.errors }">
          <input type="text" class="form-control form-control-sm form-input-style m-b-0" placeholder="{{ selectedBankType === 'E-Wallet'? ('E-Wallet Number' | translate) : ('Account Number' | translate) }}" formControlName="account_number" [ngClass]="{'form-invalid': checkValidity && form.controls.account_number.errors }">
        </div>
        <label *ngIf="selectedBankType === 'E-Wallet' && countryCode != 'ID'" class="text-left fw-bold w-100 text-blue" style="text-transform: none;">{{ 'Please do not fill in mobile number' | translate }}</label>
        <!-- <label *ngIf="selectedBankType === 'E-Wallet' && countryCode == 'ID'" class="text-left fw-bold w-100 text-blue" style="text-transform: none;">{{ 'Your e-wallet number (Premier) must be same with your registered contact number' | translate }}</label> -->
      </ng-container>

      <ng-container *ngIf="bankFieldVisible === true">
        <div class="form-input-label m-t-20">
          <p>{{ 'Bank Name' | translate }}</p>
        </div>
        <div class="input_container">
          <input class="form-control form-control-sm form-input-style m-b-10" formControlName="bank_name" type="text"
            placeholder="{{ 'Enter Your Bank Name' | translate}}"
            [ngClass]="{'form-invalid': checkValidity && form.controls.bank_name.errors }">
        </div>
      </ng-container>
      <button class="btn-blue-gra m-t-15" [ngClass]="{'disabled': disabledButton}" [disabled]="disabledButton" (click)="onSubmit(this.selectedBank, false)">{{ 'Save' | translate }}</button>
    </form>
  </div>

  <br />

  <!--Banking Details-->
  <div class="m-b-15">
    <div class="title-page-blue pl-2">
      <h3>{{ 'Banking Details' | translate }}</h3>
    </div>

    <ng-container *ngIf="bankAccounts$.length > 0">
      <div class="transaction-selection-bank m-t-10 row m-l-0 m-r-0 animateFadeIn" *ngFor="let row of bankAccounts$">
        <div class="selection-details-img col-5 p-l-0 p-r-0">
          <div *ngIf="row.icon_filename.split('.',2)[0] === 'otherbank'" class="others-overlay-outer">
            <div class="others-overlay-inner">
              <div class="text-bank">{{ row.bank_name }}</div>
            </div>
          </div>
          <ng-container *ngIf="row.icon_filename.includes('otherbank') || !row.mobile_image; else notOther">
            <img [src]="'assets/integrations/bank/otherbank.png'" [alt]="row.bank_name">
          </ng-container>
          <ng-template #notOther>
            <img [src]="row.mobile_image" [alt]="row.bank_name">
          </ng-template>
        </div>
        <div class="banking-details col-7 p-l-0 p-r-0">
          <div class="row m-l-0 m-r-0 row-height">
            <div class="col-7">
              <p>
                {{ row.account_name }} <br />
                  <span class="text-blue font-weight-normal" *ngIf="row.account_number !=''; else mobile">{{ row.account_number }}</span>
                  <ng-template #mobile>
                    <span class="text-blue font-weight-normal" *ngIf="row.mobile_number != ''; else nric">{{ row.mobile_number }}</span>
                  </ng-template>
                  <ng-template #nric>
                    <span class="text-blue font-weight-normal" *ngIf="row.nric_fin != ''; else name">{{ row.nric_fin }}</span>
                  </ng-template>
                  <ng-template #name>
                    <span class="text-blue font-weight-normal" *ngIf="row.unique_entity_name != ''; else addr">{{ row.unique_entity_name }}</span>
                  </ng-template>
                  <ng-template #addr>
                    <span class="text-blue font-weight-normal" *ngIf="row.virtual_payment_address != '';">{{ row.virtual_payment_address }}</span>
                  </ng-template>
              </p>
            </div>
            <ng-container *ngIf="row.status != null && row.verification_settings_field.length > 0">
              <div class="col-5" [ngClass]="(row.status == 2) ? 'rejected-div' : 'verification-div'">
                <ng-container *ngIf="row.status == 0 || row.status == 3">
                  <span class="under-review-icon" [innerHTML]="svg.underReviewIcon | safeHtml" alt="Reviewing"></span>
                </ng-container>
                <ng-container *ngIf="row.status == 1">
                  <span class="green-tick-icon" [innerHTML]="svg.greenTickIcon | safeHtml" alt="Verified"></span>
                </ng-container>
                <ng-container *ngIf="row.status == 2">
                  <span class="alert-icon margin-right" [innerHTML]="svg.alertIcon | safeHtml" alt="Rejected"></span>
                  <button class="reupload-button" (click)="onSubmit(null, true, row)"><span class="upload-icon" [innerHTML]="svg.uploadDocIcon | safeHtml"></span></button>  
                </ng-container>
              </div>
            </ng-container>
            <!-- <div class="col-3"> // hide for now
              <div class="p-t-10">
                <button class="btn-deactivate" (click)="onDeactivate(row.id)"> &times; </button>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>


<app-swal-alert [success]="isSuccess" [message]="messages$ | async" (confirmed)="onCloseDialog($event)" [timer]="5000">
</app-swal-alert>