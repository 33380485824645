<div class="modal show">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal1">
            <mat-horizontal-stepper linear #stepper>
                <mat-step>
                    <div class="modal-content modal-bg rejection-notice">
                        <button type="button" class="close" (click)="onCloseDialog()">×</button>  
                        <div class="notice-body">
                            <span [innerHTML]="svg.dangerIcon | safeHtml" class="msg-icon"></span>
                            <h4 class="m-b-10 m-t-10">{{ 'Verification Failed' | translate }}</h4>
                            <p>{{ 'We failed to verify your Ewallet. Kindly reupload the document request or contact Support team for assistance.' | translate }}</p>
                            <div class="ewallet-detail row">
                                <!-- Ewallet-->
                                <label class="col-5 m-0 py-1">{{ 'E-Wallet' | translate }}</label>
                                <label class="col-1 m-0 py-1 px-0">:</label>
                                <label class="col-6 m-0 py-1 px-0">{{ this.data.bank_name }}</label>
                                <!-- Account Number -->
                                <label class="col-5 m-0 py-1">{{ 'Account Number' | translate }}</label>
                                <label class="col-1 m-0 py-1 px-0">:</label>
                                <label class="col-6 m-0 py-1 px-0">{{ this.data.account_number }}</label>
                            </div>
                            <div class="d-flex justify-content-around">
                                <button type="button" class="btn-blue-gra w-50 mr-1 bottom-action-btn" (click)="onCloseDialog()">{{ 'Close' | translate }}</button>
                                <button type="button" class="btn-yellow-gra w-50 ml-1 bottom-action-btn" matStepperNext>{{ 'Reupload' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </mat-step>
                <mat-step>
                    <div class=" modal-content modal-bg">
                        <form class="row reject-dialog" [formGroup]="form" id="ewallet-verification-modal-form" name="ewallet-verification-modal-form">
                            <div class="col-12">
                                <div class="p-t-20">
                                    <div class="fancy-title mb-0">
                                        <span *ngIf="stepper.selectedIndex > 0" (click)="stepper.previous()" [innerHTML]="svg.backIcon | safeHtml" class="title-action-btn"></span>
                                        <span [innerHTML]="svg.receiptIcon | safeHtml" class="title-image"></span>
                                        <h3>{{ this.verificationTitle }}</h3>
                                        <button type="button" class="close2" (click)="onCloseDialog()">×</button> 
                                    </div>
                                    <hr class="border">
                                    <div class="col-12 p-l-0 p-r-0">
                                        <div class="ewallet-detail mb-4">
                                            <p class="text-center m-b-0">
                                                {{ 'Reason' | translate }}: {{ this.data.reason }}                                            
                                            </p>
                                         </div>
                                    </div> 
                                    <ng-container formGroupName="verification_details">
                                        <ng-container *ngFor="let value of verificationField; let i = index" [formGroupName]="value.verification_setting_field_id">
                                            <!-- Upload Document -->
                                            <div class="col-12 p-l-0 p-r-0" *ngIf="value.type_name == 'Upload File'">
                                                <p class="p-l-0 p-r-0">{{ value.label }}</p>
                                                <div class="row">
                                                    <div class="col d-flex flex-row flex-wrap dark">
                                                        <div class="receipt-box d-flex justify-content-center align-items-center" *ngFor="let image of uploaded_images[value.verification_setting_field_id]; let imgIndex = index">
                                                            <img *ngIf="!image.isPdf" [src]="image.path" alt="{{ 'Image Preview' | translate }}" />
                                                            <iframe *ngIf="image.isPdf" width="100%" height="100%" [src]="image.path" frameborder="0" style="border:0" allowfullscreen></iframe>
                                                            <div class="trash-img-div d-flex justify-content-end align-items-start">
                                                                <span [innerHTML]="svg.trashIcon | safeHtml" (click)="onRemoveFileMulti(imgIndex, value.verification_setting_field_id)"></span>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="uploaded_images[value.verification_setting_field_id]?.length < max_images" class="receipt-box d-flex justify-content-center align-items-center">
                                                            <input type="file" accept="image/*, application/pdf" (change)="onUploadFileMulti($event, value.verification_setting_field_id, i)" #myFileInput hidden />
                                                            <span *ngIf="!uploadInProgress[i]" [innerHTML]="svg.uploadIcon | safeHtml" class="upload-action-btn" (click)="myFileInput.click()"></span>
                                                            <span *ngIf="uploadInProgress[i]" class="spinner-border"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 p-l-0 p-r-0 m-b-10">
                                                    <p class="p-l-0 p-r-0 m-b-10">{{ 'Max file size is 10MB'| translate}}</p>
                                                </div>
                                            </div>
                                            <!-- Text Field -->
                                            <div class="col-12 p-l-0 p-r-0" *ngIf="value.type_name == 'Textbox'">
                                                <p class="p-l-0 p-r-0">{{ value.label }}</p>
                                                <input type="text" class="form-control form-control-sm form-input-style m-b-10" formControlName="verification_text" />
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                    <div class="col-12 p-l-0 p-r-0 text-center">
                                        <button type="submit" class="btn-blue-gra m-t-15 m-b-15 w-100" [ngClass]="{'disabled': !form.valid}" [disabled]="!form.valid" (click)="onSave()">{{ 'Submit' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                         </form>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>
<app-swal-alert [message]="messages$ | async" [success]="isSuccess" [ewalletVerification]="true"></app-swal-alert>