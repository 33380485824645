import { Component, OnInit } from '@angular/core';
import { DynamicFooterService } from '@core/services/dynamic-footer.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { isLoggedIn } from '@core/store/auth/auth.selectors';
import { Store, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-footer',
  templateUrl: './dynamic-footer.component.html',
  styleUrls: ['./dynamic-footer.component.scss']
})
export class DynamicFooterComponent implements OnInit {

  footerList: any[];
  countryCode = localStorage.getItem('country_code');
  currentPageSeoFooter = '';
  currentGameCategory = '';

  showRow = 0;
  lastRow = 0;

  siteName = environment.sitePrefix.toUpperCase();

  constructor(
    public translateService: TranslateService,
    private dynamicFooterService: DynamicFooterService,
    private router: Router,
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.store.pipe(select(isLoggedIn)).subscribe(() => {
      of(null).pipe(
        delay(500), tap(() => {
          this.dynamicFooterService.getFooter().subscribe((res) => {
            this.footerList = res;
            this.lastRow = res[res.length - 1]['mobile_row'];
          })
        })).subscribe();
    })
  }

  counter(i: number) {
    return new Array(i);
  }

  updateShowRowSpace(number) {
    this.showRow = number;
  }

  onRedirect(link: string) {
    if (link !== (null && '')) {
      if (link.includes('/external?link=')) {
        var redirectTO = link.replace('/external?link=', '');
        window.open(redirectTO, '_blank');
      } else {
        this.router.navigateByUrl(link);
      }
    }
  }

}
