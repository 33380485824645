import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import svgIconList from "assets/icons.json";
import { WebsocketService } from "@core/services/websocket.service";
import { AuthData } from "@core/models/auth-data";

type LowercaseKYC = "basic" | "advanced" | "pro"; // can't use Lowercase<> due to version < 4.1

type MemberProfileUpdatedData = {
  member: any;
  kyc: {
    status: "Basic" | "Advanced" | "Pro";
    allowed?: boolean;
  };
};

type MemberFirstTimeDepositedData = {
  ftd: boolean;
};

@Component({
  selector: "app-floating-kyc-notification",
  templateUrl: "./floating-kyc-notification.component.html",
  styleUrls: ["./floating-kyc-notification.component.scss"],
})
export class FloatingKycNotificationComponent implements OnInit {
  svg: any = svgIconList;

  minimizeDialog = true;
  showBallon = false;

  constructor(
    private router: Router,
    private websocketService: WebsocketService
  ) {}

  ngOnInit() {
    const userData = this.getUserData();

    // initial ballon show/hide
    this.showOrHideBallon(userData?.ftd_status);

    if (this.showBallon) {
      // slightly delay KYC dialog
      setTimeout(() => {
        this.onTogglePopup(true);
      }, 3000);
    }

    // websocket events
    this.listenToMemberProfileUpdated();
    this.listenToMemberFirstTimeDeposited();
  }

  getUserData(): AuthData {
    return JSON.parse(localStorage.getItem("user_data"));
  }

  setLocalStorageUserData(userData: AuthData) {
    localStorage.setItem("user_data", JSON.stringify(userData));
  }

  onTogglePopup(forceExpand: boolean = false) {
    this.minimizeDialog = forceExpand ? false : !this.minimizeDialog;
    this.setRequestDialog();
  }

  @HostListener("window:resize", ["$event"])
  setRequestDialog(event?) {
    let requestDialog = document.getElementById("kyc-notification-dialog");

    requestDialog.style.top = (window.innerHeight - requestDialog.offsetHeight) / 2 + "px";
    requestDialog.style.left = "0";

    if (this.minimizeDialog) {
      let ballon = document.getElementsByClassName("kyc-noti-floating-content")[0]?.getBoundingClientRect(),
        requestDialog = document.getElementById("kyc-notification-dialog");

      if (!ballon) {
        return;
      }

      requestDialog.style.top = ballon.top - requestDialog.offsetHeight / 2 + "px";
      requestDialog.style.left = ballon.left - requestDialog.offsetWidth / 2 + "px";
    }
  }

  onRedirect() {
    this.onTogglePopup();
    this.router.navigateByUrl(this.websocketService.getCTAPath("profile"));
  }

  onCloseBallon() {
    this.showBallon = false;
  }

  listenToMemberProfileUpdated() {
    const channelName = "member-profile-updated-channel." + this.getUserData()?.id;
    const event = ".MemberProfileUpdated";

    this.websocketService
      .listenToPrivateEvent(channelName, event)
      .subscribe((data: MemberProfileUpdatedData) => {
        const kyc = data.kyc.status.toLowerCase() as LowercaseKYC;

        // update local storage KYC
        // note that profile page there will update this user_data again on GET /member/profile
        this.setLocalStorageUserData({
          ...this.getUserData(),
          KYC: kyc,
        });

        this.showOrHideBallon(this.getUserData()?.ftd_status);
      });
  }

  listenToMemberFirstTimeDeposited() {
    const channelName = "member-first-time-deposited-channel." + this.getUserData()?.id;
    const event = ".MemberFirstTimeDeposited";

    this.websocketService
      .listenToPrivateEvent(channelName, event)
      .subscribe((data: MemberFirstTimeDepositedData) => {
        if (data.ftd) {
          // update local storage ftd_status
          this.setLocalStorageUserData({
            ...this.getUserData(),
            ftd_status: true,
          });

          this.showOrHideBallon(true);

          // show dialog
          this.onTogglePopup(true);
        }
      });
  }

  showOrHideBallon(isFTD: boolean) {
    // need to always get latest user_data from local storage
    const userData = this.getUserData();

    const isNormalGroup = userData?.member_group?.name.toLowerCase() === "normal";
    const kyc = userData?.KYC.toLowerCase();

    this.showBallon = isFTD && isNormalGroup && kyc.toLowerCase() !== "pro";
  }
}
