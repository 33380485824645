import { Injectable } from '@angular/core';
import { BankHttpService } from './bank-http.service';
import { environment } from '@env/environment';
import { MemberBankHttpService } from './member-bank-http.service';
import { MerchantBankHttpService } from './merchant-bank-http.service';

@Injectable({
  providedIn: 'root'
})
export class DropdownHttpService {

  constructor(
    private merchantBankHttpService: MerchantBankHttpService,
    private memberBankHttpService: MemberBankHttpService,
    private bankHttpService: BankHttpService,) { }
  merchantBankAccountsDeposit = this.merchantBankHttpService.getMerchantBankDeposits();
  memberBanks = this.memberBankHttpService.getMemberBanks();
  banks = this.bankHttpService.getAllBanks();
  paymentMethods = this.bankHttpService.getPaymenyMethods();

  // Note: No DB hanlder for now...
  uploadTypes = [
    null,
    'banners',
    'receipts',
    'promotions',
    'uploads',
    'upload/card/photo/front/',
    'upload/card/photo/holding'
  ];

  regex = {
    MY: /^(0|60|0060|\+60)1[0-9]{8,9}$/,
    TH: /^(\+66|66)(\d{3,4}?){2}\d{3,4}?$|^(0[689]|[689])(\d{3,4}?){1}\d{3,4}?$/,
    SG: /^(65[8-9]|8|9)([0-9][0-9]{6})+$/,
    ID: /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/,
    IN: /^(^\+91[6-9]|91[6-9]|[6-9])\d{9,10}$/,
    PH: /^(0|63)[0-9]{10}$/
  };

  // @see app.component.ts->nonRegionalLocalization
  // property is created so that when the domain matches the specified domain below
  // function will automatically detect the user's IP country and display the lang/country code accordingly
  nonRegionalDomains = [
    // Local
    'localhost',
    // QA
    'qa-qp1a-up.jk188.net', 'qa-qp1b-up.jk188.net', 'qa-qp1a-mup.jk188.net', 'qa-qp1b-mup.jk188.net', 'cms2qa-qp1a-up.jk188.net', 'cms2qa-qp1b-up.jk188.net', 'cms2qa-qp1a-mup.jk188.net', 'cms2qa-qp1b-mup.jk188.net', 'qa-qpro2-up.jk188.net', 'qa-qpro2-mup.jk188.net', 'qa-qpro3-up.jk188.net', 'qa-qpro3-mup.jk188.net', 'qa-qpro4-up.jk188.net', 'qa-qpro4-mup.jk188.net', 'qa-qpro5-up.jk188.net', 'qa-qpro5-mup.jk188.net',
    // UAT
    'up2.porsche68.com', 'bp9up.porsche68.com', 'm2.porsche68.com', 'bp9mup.porsche68.com', 'bp9mup.porsche68.com', 'qpro2mup.porsche68.com', 'qpro3mup.porsche68.com', 'qpro4mup.porsche68.com', 'qpro5mup.porsche68.com',
    // Production 
    'bp9yyds.com', 'm.bp9yyds.com', 'bp9yyds1.com', 'm.bp9yyds1.com', 'bp9yyds2.com', 'm.bp9yyds2.com', 'bp9yyds3.com', 'm.bp9yyds3.com', 'vip1-bp9.com', 'm.vip1-bp9.com', 'vip2-bp9.com', 'm.vip2-bp9.com', 'vip3-bp9.com', 'm.vip3-bp9.com',
    // App domain : DO NOT REMOVE, UNLESS APP DOMAIN IS CHANGED
    'bp9.app', 'm.bp9.app'
  ];

  availableCountryAndLanguages = {
    // Primary
    [environment.domainName]: {
      MY: {
        name: 'Malaysia',
        languages: ['EN', 'ZH'], // Supported language for this country
        mobilePreExt: {
          production: 'mobile.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        }, // When redirect to mobile version will add this in front
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: ''
        }, // When redirect to desktop version will add this in front
        // Domain list, based on environment.domainEnv value to decide which to use
        // When production = true only will look for this value, else act like normal ng serve
        production: [
          'bp77myr.net', 'bp77my.co', 'bp77my.net', 'bp77my.com', 'bp77mys.com', 'bp77yyds.com', 'bp77myr.co', 'bp77myr.com', 'bp77mys.net',
          // App domain : DO NOT REMOVE, UNLESS APP DOMAIN IS CHANGED
          'bp9.app'], // NOTE: bp77yyds.com is excluded from SEO Indexing
        demo: ['porsche68.com'],
        qa: ['jk188.net'],
        staging: ['jk188.net'],
        local: ['localservermybp77.com', 'localservermy.com', 'localservermy.net', 'localservermy.co', 'localservermyasia.com'],
        // To use on bp77 redirect to bp9 **NEED TO UPDATE THIS WHEN GO PRODUCTION**
        redirect: 'bp9my.com'
      },
      SG: {
        name: 'Singapore',
        languages: ['EN', 'ZH'],
        mobilePreExt: {
          production: 'mobile.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        },
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: ''
        },
        production: ['bp77b.com', 'bp77sgp.com', 'bp77sgd.com', 'bp77sgd.net', 'bp77sg.com', 'bp77sg.co', 'bp77sg.net'],
        demo: [''],
        qa: ['jk188.net'],
        staging: ['jk188.net'],
        local: ['localserversg.com', 'localserversg.net', 'localserversgasia.com'],
        redirect: null
      },
      TH: {
        name: 'Thailand',
        languages: ['EN', 'TH'],
        mobilePreExt: {
          production: 'm.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        },
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: ''
        },
        production: ['bp77th.com', 'bp77th.co', 'bp77th.net'],
        demo: [''],
        qa: ['jk188.net'],
        staging: ['jk188.net'],
        local: [],
        redirect: null
      },
      ID: {
        name: 'Indonesia',
        languages: ["EN", "ID"],
        mobilePreExt: {
          production: 'mobile.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        },
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: ''
        },
        production: ['bp77idn.co', 'bp77idr.net', 'bp77idr.com', 'bp77id.co', 'bp77id.net', 'bp77id.com', 'bp77idn.com', 'bp77idn.net'],
        demo: [''],
        qa: ['jk188.net'],
        staging: ['jk188.net'],
        local: ['localserverid.com', 'localserverid.net', 'localserveridasia.com'],
        redirect: null
      },
      // TEMPORARY DISABLED FOR PRODUCTION
      // IN: {
      //   name: 'India',
      //   languages: ["EN"],
      //   mobilePreExt: 'm.',
      //   production: ['bp77in.com'],
      //   demo: ['porsche68in.com'],
      //   staging: ['jk188in.net'],
      //   local: ['localserverin.com', 'localserverin.net', 'localserverinasia.com']
      // }
    },
    // First Alternative
    [environment.altDomainName]: {
      MY: {
        name: 'Malaysia',
        languages: ['EN', 'ZH'], // Supported language for this country
        mobilePreExt: {
          production: 'mobile.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        }, // When redirect to mobile version will add this in front
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: ''
        }, // When redirect to desktop version will add this in front
        // Domain list, based on environment.domainEnv value to decide which to use
        // When production = true only will look for this value, else act like normal ng serve
        production: ['bp77mys.co'],
        demo: [''],
        qa: [''],
        staging: [''],
        local: [''],
        redirect: null
      },
      SG: {
        name: 'Singapore',
        languages: ['EN', 'ZH'],
        mobilePreExt: {
          production: 'm.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        },
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: ''
        },
        production: ['bp77s1.com', 'bp77sgd.co', 'bp77sg.co'],
        demo: [''],
        qa: [''],
        staging: [''],
        local: [''],
        redirect: null
      },
      TH: {
        name: 'Thailand',
        languages: ['EN', 'TH'],
        mobilePreExt: {
          production: 'm.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        },
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: ''
        },
        production: ['bp77th.co'],
        demo: [''],
        qa: [''],
        staging: [''],
        local: [''],
        redirect: null
      },
      ID: {
        name: 'Indonesia',
        languages: ["EN", "ID"],
        mobilePreExt: {
          production: 'm.',
          demo: 'm2.',
          qa: 'qa-qp1a-mup.',
          staging: 'm2.',
          local: 'mobile.'
        },
        desktopPreExt: {
          production: '',
          demo: 'up2.',
          qa: 'qa-qp1a-up.',
          staging: 'up2.',
          local: '',
        },
        production: ['bp77id1.com', 'bp77idr.co'],
        demo: [''],
        qa: [''],
        staging: [''],
        local: [''],
        redirect: null
      },
      // TEMPORARY DISABLED FOR PRODUCTION
      // IN: {
      //   name: 'India',
      //   languages: ["EN"],
      //   mobilePreExt: 'm.',
      //   production: ['bp77in.co'],
      //   demo: ['porsche68in.com'],
      //   staging: ['jk188in.net'],
      //   local: ['localserverin.co']
      // }
    },
    // Second Alternative
    [environment.altDomainNameTwo]: {
      MY: {
        name: 'Malaysia',
        languages: ['EN', 'ZH'], // Supported language for this country
        mobilePreExt: {
          production: '',
          demo: '',
          qa: '',
          staging: '',
          local: ''
        }, // When redirect to mobile version will add this in front
        desktopPreExt: {
          production: '',
          demo: '',
          qa: '',
          staging: '',
          local: ''
        }, // When redirect to desktop version will add this in front
        // Domain list, based on environment.domainEnv value to decide which to use
        // When production = true only will look for this value, else act like normal ng serve
        production: [''],
        demo: [''],
        qa: [''],
        staging: [''],
        local: [''],
        redirect: null
      }
    }
  }

  affiliateDomain = {
    MY: {
      mobilePreExt: {
        production: 'maff.',
        demo: 'map.',
        staging: 'map.',
        qa: 'qa-qp1a-maff.',
        local: 'map.'
      },
      desktopPreExt: {
        production: 'aff.',
        demo: 'ap.',
        staging: 'ap.',
        qa: 'qa-qp1a-aff.',
        local: 'ap.'
      },
      production: ['bp77myr.net', 'bp77my.com'],
      demo: ['porsche68.com'],
      staging: ['jk188.net'],
      qa: ['jk188.net'],
      local: ['localserver.com']
    },
    SG: {
      mobilePreExt: {
        production: 'maff.',
        demo: 'map.',
        staging: 'map.',
        qa: 'qa-qp1a-maff.',
        local: 'map.'
      },
      desktopPreExt: {
        production: 'aff.',
        demo: 'ap.',
        staging: 'ap.',
        qa: 'qa-qp1a-aff.',
        local: 'ap.'
      },
      production: ['bp77b.com', 'bp77sgp.com', 'bp77sgd.com', 'bp77sg.com'],
      demo: [''],
      staging: ['jk188.net'],
      qa: ['jk188.net'],
      local: ['localserver.com']
    },
    TH: {
      mobilePreExt: {
        production: 'maff.',
        demo: 'map.',
        staging: 'map.',
        qa: 'qa-qp1a-maff.',
        local: 'map.'
      },
      desktopPreExt: {
        production: 'aff.',
        demo: 'ap.',
        staging: 'ap.',
        qa: 'qa-qp1a-aff.',
        local: 'ap.'
      },
      production: ['bp77th.com', 'bp77thb.com'],
      demo: [''],
      staging: ['jk188.net'],
      qa: ['jk188.net'],
      local: ['localserver.com']
    },
    ID: {
      mobilePreExt: {
        production: 'maff.',
        demo: 'map.',
        staging: 'map.',
        qa: 'qa-qp1a-maff.',
        local: 'map.'
      },
      desktopPreExt: {
        production: 'aff.',
        demo: 'ap.',
        staging: 'ap.',
        qa: 'qa-qp1a-aff.',
        local: 'ap.'
      },
      production: ['bp77id.net', 'bp77id.com', 'bp77idr.com'],
      demo: [''],
      staging: ['jk188.net'],
      qa: ['jk188.net'],
      local: ['localserver.com']
    }
  }

  vipHiddenDomains = [];

  // Available language in our site
  languages = {
    EN: "English",
    ZH: "中文",
    MS: "Malay",
    TH: "ไทย",
    ID: "B. Indonesia",
    HI: "हिन्दी"
  }
}
