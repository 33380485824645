import { animate, sequence, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ISOCountryCode } from '@core/enums/ISO-country-code.enum';
import { AuthData } from '@core/models/auth-data';
import { AuthHttpService } from '@core/services/auth-http.service';
import { DashboardService } from '@core/services/dashboard.service';
import { DropdownHttpService } from '@core/services/dropdown-http.service';
import { environment } from '@env/environment';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { MenuBarHttpService } from '@core/services/menu-bar-http.service';
import { WebsocketService } from '@core/services/websocket.service';
import { AuthActions } from '@core/store/auth/auth-action.types';
import { isLoggedIn, loggedUser } from '@core/store/auth/auth.selectors';
import { LiveChatWidgetModel } from '@livechat/angular-widget';
import { select, Store } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LanguageModalComponent } from '@shared/language-modal/language-modal.component';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { AppState } from '@store/reducers';
import { PredictionDataService } from '@views/modules/games/services/prediction-data.service';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import svgIconList from 'assets/icons.json';

declare var $: any;
@Component({
  selector: 'app-sidepanels',
  templateUrl: './sidepanels.component.html',
  styleUrls: ['./sidepanels.component.scss'],
  animations: [trigger('dropDownMenu', [
    transition(':enter', [
      style({ height: 0, overflow: 'hidden' }),
      sequence([
        animate('200ms', style({ height: '*' }))
      ])
    ]),
    transition(':leave', [
      style({ height: '*', overflow: 'hidden' }),
      sequence([
        animate('200ms', style({ height: 0 }))
      ])
    ])
  ])]
})
export class SidePanelsComponent implements OnInit, AfterViewInit {
  svg: any = svgIconList;

  @ViewChild('liveChatWidget', { static: false }) public liveChatWidget: LiveChatWidgetModel;
  mode: string = window.mode;
  userProfile: Observable<AuthData>;
  public isLiveChatWidgetLoaded = false;
  language = localStorage.getItem('language_code') === 'MS' ? 11 : localStorage.getItem('language_code') === 'ZH' ? 10 : 9;
  version = localStorage.getItem('api_version');
  isLoggedIn$: Observable<boolean>;
  userData = JSON.parse(localStorage.getItem('user_data'));

  private unsubscribe: Subscription[] = [];

  activeRewards$ = this.dashboardService.activeRewards$;

  ISOCountryCode = ISOCountryCode;

  showChangeCountryNotice = false;
  isOpenGameMenu = false;
  isOpenInfoMenu = false;
  gameMenuName = ['Casino', 'Slots', 'Lottery', 'Sports', 'Fishing', 'Esports', 'Crash Game', 'Table'];
  gameMenuLinks = ['/casino', '/slots', '/lottery', '/sports', '/fishing', '/esports', '/crash-game', '/table'];
  infoMenuName = ['About Us', 'Frequently Asked Questions', 'Terms and Conditions', 'Privacy Policy', 'Responsible Gaming'];
  infoMenuLinks = ['/info-center?tab=about_us', '/info-center?tab=faq', '/info-center?tab=termsconditions', '/info-center?tab=privacy_policy', '/info-center?tab=responsible_gaming'];
  isActiveGameMenu: number;
  isActiveInfoMenu: number;
  countryCode = localStorage.getItem('country_code') === null ? 'MY' : localStorage.getItem('country_code');
  isPredictionTabClicked = true;

  affiliateDomain = this.dropdownService.affiliateDomain;
  affiliateUrl = '';

  isActiveSubMenu = [];
  isOpenSubMenu: boolean[] = [];
  menuLists = [];
  contactList = sessionStorage.getItem('contact_us') === null ? [] : JSON.parse(sessionStorage.getItem('contact_us'));
  isLine = false;
  lineURL: any;
  lineContent: any;

  constructor(
    public dialog: MatDialog,
    private authService: AuthHttpService,
    private store: Store<AppState>,
    private eventEmitterService: EventEmitterService,
    public loadingBar: LoadingBarService,
    private dashboardService: DashboardService,
    private router: Router,
    private dropdownService: DropdownHttpService,
    private predictionDataService: PredictionDataService,
    private menuBarHttpService: MenuBarHttpService,
    private websocketService: WebsocketService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.isLoggedIn$ = this.store.pipe(select(isLoggedIn));
    this.userProfile = this.store.pipe(select(loggedUser));

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      if (window.location.pathname.length > 1) {
        this.isActiveGameMenu = this.gameMenuLinks.toString().indexOf(window.location.pathname.split('/')[1]);
        this.isActiveInfoMenu = (['/info-center/contact-us', '/info-center/sitemap'].includes(window.location.pathname)) ? -1 : this.infoMenuLinks.toString().indexOf(window.location.pathname.split('/')[1]);
        this.isOpenGameMenu = this.isActiveGameMenu >= 0;
      } else {
        this.isActiveInfoMenu = -1;
        this.isActiveGameMenu = -1;
      }
    });

    this.eventEmitterService.contactUsVar = this.eventEmitterService.updateContactUsEmitter.subscribe(() => {
      this.contactList = JSON.parse(sessionStorage.getItem('contact_us'));
      // Hard code 'Line' floating icon
      this.lineContent = this.contactList.find(x => x.contact_platform.name === 'Line');
      this.isLine = this.lineContent !== undefined ? true : false;
      this.lineURL = this.lineContent !== undefined && this.lineContent.locales.length > 0 ? this.lineContent.locales[0].link : '';
    });

    this.eventEmitterService.sidepanelEventClicked.subscribe(res => this.isPredictionTabClicked = res);

    setTimeout(() => { // delayed to get the country on domain
      this.getAffiliateUrl();
    }, 2000)

    this.isLoggedIn$.subscribe((res) => {
      setTimeout(() => {
        this.getMenuBar();
      }, res ? 500 : 0);
    });
  }

  ngAfterViewInit() {
    this.isLoggedIn$.subscribe(res => {
      if (res) {
        setTimeout(() => {
          this.countryCode = localStorage.getItem('country_code');
          if (this.countryCode !== 'ID') {
            this.gameMenuName.splice(2, 1);
            this.gameMenuLinks.splice(2, 1);
          } else {
            this.gameMenuName = ['Casino', 'Slots', 'Lottery', 'Sports', 'Fishing', 'Esports', 'Crash Game', 'Table'];
            this.gameMenuLinks = ['/casino', '/slots', '/lottery', '/sports', '/fishing', '/esports', '/crash-game', '/table'];
          }
          if (window.location.pathname !== '/gamelaunch') {
            this.dashboardService.getActiveRewards().subscribe(res => this.dashboardService.activeRewards$.next(res.length));
          }

          var userdata = JSON.parse(localStorage.getItem('user_data'));
          const memberAccountId = userdata.id;
          // check whether to show noti for "Prediction" tab
          this.predictionDataService.checkMemberPredictionTab(memberAccountId).subscribe(res => {
            this.isPredictionTabClicked = res.clicked;
          });
          this.updateMemberPendingUploadReceipt(memberAccountId);

          // No need to call websocket when in '/gamelaunch' page
          if (window.location.pathname !== '/gamelaunch') {
            this.updateMemberEventClickWebsocket(memberAccountId);
            this.createEventSendNotificationWebsocket();
          }
        }, 500)
      } else {
        if (this.countryCode !== 'ID') {
          this.gameMenuName.splice(2, 1);
          this.gameMenuLinks.splice(2, 1);
        } else {
          this.gameMenuName = ['Casino', 'Slots', 'Lottery', 'Sports', 'Fishing', 'Esports', 'Crash Game', 'Table'];
          this.gameMenuLinks = ['/casino', '/slots', '/lottery', '/sports', '/fishing', '/esports', '/crash-game', '/table'];
        }
      }
    });
  }

  onHideSideBar(isDropDown: boolean) {
    // if (!isDropDown) {
    //   $('body').toggleClass("side-panel-open");
    //   this.document.body.classList.remove('side-panel-open');
    // } else {
    //   this.eventEmitterService.onRemoveBodyClass(isDropDown);
    // }
    this.eventEmitterService.onRemoveBodyClass(isDropDown);
  }

  onOpenChatBox() {
    this.liveChatWidget.openChatWindow();
  }

  openChatWindow(): void {
    if (this.isLiveChatWidgetLoaded) {
      this.liveChatWidget.openChatWindow();
    }
  }

  onLogout() {
    this.loadingBar.start();
    this.authService.logout().subscribe(() => {
      window.location.href = this.mode == 'Members Only' ? '/login' : '/';
      localStorage.removeItem('reward_notice');
      sessionStorage.removeItem('slots_history');
      sessionStorage.removeItem('fishing_history');
      sessionStorage.removeItem('crash_game_history');
      sessionStorage.removeItem('table_history');
      sessionStorage.removeItem('casino_history');
      sessionStorage.removeItem('promotionSession');
      localStorage.removeItem('available_providers');
      this.store.dispatch(AuthActions.logout());
    });
  }

  onOpenDialog(link: string) {
    let type = link.includes('popup') ? link?.substring(link.indexOf('#/popup/') + '#/popup/'.length) : link;
    switch (type) {
      case 'login':
        if (this.mode == 'Members Only') {
          this.router.navigate(['/login'])
        } else {
          this.openDialogBy(LoginModalComponent, 'login');
        }
        break;

      case 'language':
        this.openDialogBy(LanguageModalComponent, 'language');
        break;
    }
  }

  onDropdownNavigate(subMenu: any) {
    this.eventEmitterService.onSideBarGameCategoryEmitter(subMenu.name.toLowerCase());

    setTimeout(() => {
      if(subMenu.link.startsWith('/external?link=')) {
        window.location.href = subMenu.link.replace('/external?link=', '');
      }else{
        this.router.navigateByUrl(subMenu.link);
        this.document.body.classList.remove('side-panel-open');
        this.document.body.classList.remove('no-scroll');
      }
    }, 500);
  }

  navToAff() {
    window.location.href = this.affiliateUrl;
  }

  isUserLoggedIn() {
    return this.authService.isUserLoggedIn;
  }

  private openDialogBy(componentRef: any, type: string) {
    if (type !== 'language') {
      this.dialog.open(componentRef, {
        width: '800px',
      });
    } else {
      this.dialog.open(componentRef);
    }
  }

  private getAffiliateUrl() {
    let affCountryObj = this.affiliateDomain[this.countryCode];
    let targetDomain = this.getCurrentDomain();
    this.affiliateUrl = (environment.https ? 'https://' : 'http://') + affCountryObj.mobilePreExt[environment.domainEnv] + (affCountryObj[environment.domainEnv].find(domain => domain == targetDomain) ? targetDomain : affCountryObj[environment.domainEnv][0]) + `?lang=${this.countryCode}_${localStorage.getItem('language_code')}`;
  }

  getCurrentDomain() {
    let hostname = window.location.hostname;
    let domain = hostname.replace(/^(m\.|mobile\.|www\.|maff\.)/, '');
    return domain;
  }

  private updateMemberEventClickWebsocket(memberAccountId) {
    this.websocketService
      .getChannel("update-member-event-click-channel." + memberAccountId)
      .listen(`.UpdateMemberEventClickEvent`, (data) => {
        this.isPredictionTabClicked = data.clicked;
        if (data.clicked == 0) {
          data.events.forEach(id => this.eventEmitterService.eventListStatusUpdate.emit(id));
          this.eventEmitterService.eventListStatusLabelUpdate.emit(data.statuses);
        }
      });
  }

  private createEventSendNotificationWebsocket() {
    this.websocketService
      .getChannel('create-event-send-notification-channel')
      .listen(`.CreateEventSendNotificationEvent`, (data) => {
        this.isPredictionTabClicked = data.clicked;
      });
  }

  navigateTo(menuList: any) {
    if (menuList.link == '/affiliate') {
      this.navToAff();
      this.document.body.classList.remove('side-panel-open');
      this.document.body.classList.remove('no-scroll');
    } else if(menuList.link.startsWith('/external?link=')) {
      window.location.href = menuList.link.replace('/external?link=', '');
    } else {
      this.eventEmitterService.onSideBarGameCategoryEmitter(menuList.name.toLowerCase());
      setTimeout(() => {

        // if go to home page , reset gameCategoryCode and activeGameCategoryName as default
        if(menuList.link === '/'){
          localStorage.removeItem('gameCategoryCode');
          localStorage.removeItem('gameCategoryName');
        }
        
        this.router.navigateByUrl(menuList.link);
        this.document.body.classList.remove('side-panel-open');
        this.document.body.classList.remove('no-scroll');
      }, 500);
    }
  }

  getMenuBar() {
    this.menuBarHttpService.getMenuBar().subscribe(res => {
      this.menuLists = res;

      var newMenu = res.find(x => x.new == 1) ? true : false;
      if(newMenu){
        this.eventEmitterService.onCheckNewMenu(newMenu);
      }else{
        this.menuLists.forEach((menu, i) => {
          if(menu.sub_menu && !newMenu){
            newMenu = menu.sub_menu.find(x => x.new == 1) ? true : false;
            this.eventEmitterService.onCheckNewMenu(newMenu);
          }
        });
      }

      res.forEach((menu, i) => {
        this.isOpenSubMenu[i] = false;
      });

      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(() => {
        this.menuLists.forEach((menu, x) => {
          const location = menu.sub_menu.filter(x => x.link === window.location.pathname);
          this.isActiveSubMenu[x] = location.length;
        });
      });

    });
  }

  private updateMemberPendingUploadReceipt(memberAccountId) {
    this.websocketService
      .getChannel("pending-upload-receipt." + memberAccountId)
      .listen(`.PendingUploadReceiptEvent`, (data) => {
        localStorage.setItem('pending_deposit_receipt', data.pending_receipt);
      });
  }

  onOpenLine() {
    window.open(this.lineURL, "_blank");
  }

  checkActiveRoute(link: string) {
    // To handle url like '/promotion?code='
    const baseLink = link.split('?')[0];

    if (link.startsWith('/external?link=')) {
      return false;
    }else if (baseLink == window.location.pathname) {
      return true;
    }

    return false;
  }

  checkSubMenu(subMenus) {
    var res = (subMenus.find(x => x.new == 1) ? true : false);
    return res;
  }

}
