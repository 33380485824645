import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from '@core/services/event-emitter.service';
import { PortalMemberProfileHttpService } from '@core/services/portal-member-profile-http.service';
import { tap } from 'rxjs/operators';
import svgIconList from 'assets/icons.json';

@Component({
  selector: 'app-floating-reward',
  templateUrl: './floating-reward.component.html',
  styleUrls: ['./floating-reward.component.scss']
})
export class FloatingRewardComponent implements OnInit {
  svg: any = svgIconList;

  minimizeDialog = true;
  showBallon = false;
  isSuccess = this.rewardService.isSuccess;
  rewardsData: any = null;

  constructor(
    private rewardService: PortalMemberProfileHttpService,
    private router: Router,
    private eventEmitterService: EventEmitterService,
  ) { }


  ngOnInit() {
    this.onGetRewardDetails();
    this.eventEmitterService.updateRewardBubbleEmitter.subscribe(() => {
      this.onGetRewardDetails();
    });
  }

  onTogglePopup(forceExpand: boolean = false) {
    this.minimizeDialog = forceExpand ? false : !this.minimizeDialog;
    this.setRequestDialog()
    if (this.rewardsData == null) this.minimizeDialog = true;
    if (this.rewardsData.show_reward == 1 && this.rewardsData.pending_reward > 0) {
      this.showBallon = true;
    } else {
      this.showBallon = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  setRequestDialog(event?) {
    var requestDialog = document.getElementById('rewards-dialog');
    requestDialog.style.top = (window.innerHeight - requestDialog.offsetHeight) / 2 + 'px';
    requestDialog.style.left = '0';

    if (this.minimizeDialog) {
      var ballon = document.getElementsByClassName('rewards-floating-content')[0]?.getBoundingClientRect(),
        requestDialog = document.getElementById('rewards-dialog');

      if (!ballon) {
        return;
      }

      requestDialog.style.top = ballon.top - (requestDialog.offsetHeight / 2) + 'px'
      requestDialog.style.left = ballon.left - (requestDialog.offsetWidth / 2) + 'px';
    }
  }

  onRedirect(){
    this.onTogglePopup();
    this.router.navigateByUrl('/member/reward');
  }

  onGetRewardDetails(){
    this.rewardService.getRewardDetails().pipe(
      tap(res => {
        this.rewardsData = res;
        if (this.rewardsData.show_reward == 1 && this.rewardsData.pending_reward > 0) {
          this.showBallon = true;
        } else if (this.rewardsData.pending_reward == 0) {
          this.showBallon = false;
        }
      }),
    ).subscribe();

  }

  onNotShowAgain(e: Event) {
    const checked = (e.target as HTMLInputElement).checked ? 0 : 1; 
    const data = {
      show_reward: checked,
    };
    this.rewardService.updateShowRewards(data).pipe(
      tap(res => {
        this.isSuccess = this.rewardService.isSuccess;
        this.onGetRewardDetails();
      }),
    ).subscribe();
  }

}
